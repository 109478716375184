/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Constants or States */
const initData = {
    clear: false,
    subject: false,
    customersList: []
}

const UPDATE_CLEAR = "UPDATE_CLEAR";
const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";


/* Reducer (Save in constant) */
export default function emailsReducer(state = initData, action) {
    switch (action.type) {
        case UPDATE_CLEAR:
            return {...state, clear: action.payload.clear }
        case UPDATE_CUSTOMERS:
            return {...state, customersList: action.payload.customers }
        default:
            return {...state }
    }
}

/* Actions */
export const updateClearFields = ({ clear = "" }) => async(dispatch) => {
    dispatch({
        type: UPDATE_CLEAR,
        payload: { clear: clear }
    });
}

export const updateCustomers = ({ customers = "" }) => async(dispatch) => {
    dispatch({
        type: UPDATE_CUSTOMERS,
        payload: { customers: customers }
    });
}