import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./style/materialize.scss";
import App from "./App.jsx";

/* Redux */
import { Provider } from "react-redux";
import generateStore from "./redux/store";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import Loading from "components/loading/Loading";

// Init store
const store = generateStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loading fullHeight={true} />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
