/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Constants or States */
const initData = {
    pageActive: "",
};

const UPDATEPAGE = "UPDATEPAGE";

/* Reducer (Save call API in constant) */
export default function sideNavMobileReducer(state = initData, action) {
    switch (action.type) {
        case UPDATEPAGE:
            return {...state, pageActive: action.payload.pageName };
        default:
            return {...state };
    }
}

/* Actions (Calls API) */
export const changePage =
    ({ pageName = "Account" }) =>
    (dispatch) => {
        dispatch({
            type: UPDATEPAGE,
            payload: {
                pageName,
            },
        });
    };