/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import { db, analytics } from "../controller/firebase";

import { openToast, ERROR, SUCCESS } from "../constant/Toast";

import { DiffInHours } from "../helpers/GetDifferenceBetweenDates";

import i18n from "i18next";
import { ListFirebaseCustomError } from "../helpers/ListFirebaseCustomErrors";

/* Constants or States */
const initData = {
  loading: true,
  loadingCustomers: false,
  data: [
    {
      flowSequence: null,
    },
  ],
  timeCreated: null,
  dateCreated: null,
};

const LOADING_MENU = "LOADING_MENU";
const LOADING_CUSTOMERS_SCAN = "LOADING_CUSTOMERS_SCAN";

const GET_MENU_EXIT = "GET_MENU_EXIT";
const GET_MENU_ERROR = "GET_MENU_ERROR";

const ADD_CUSTOMER_EXIT = "ADD_CUSTOMER_EXIT";
const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";

const DISABLE_CUSTOMER_EXIT = "DISABLE_CUSTOMER_EXIT";
const DISABLE_CUSTOMER_ERROR = "DISABLE_CUSTOMER_ERROR";

const GET_TIME_CREATED_EXIT = "GET_TIME_CREATED_EXIT";
const GET_TIME_CREATED_ERROR = "GET_TIME_CREATED_ERROR";

const GET_URL_REAL_EXIT = "GET_URL_REAL_EXIT";
const GET_URL_REAL_ERROR = "GET_URL_REAL_ERROR";

const UPDATE_TIME_CREATED = "UPDATE_TIME_CREATED";

/* Reducer (Save call API in constant) */
export default function scanReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_MENU:
      return { ...state, loading: true };
    case LOADING_CUSTOMERS_SCAN:
      return { loadingCustomers: true };
    case GET_MENU_EXIT:
      return { ...state, data: { ...action.payload }, loading: false };
    case GET_MENU_ERROR:
      return { ...initData };
    case ADD_CUSTOMER_EXIT:
      return {
        ...state,
        timeCreated: action.payload.diffTime,
        dateCreated: action.payload.dateCreated,
        loading: false,
      };
    case ADD_CUSTOMER_ERROR:
      return { ...initData };
    case GET_TIME_CREATED_EXIT:
      return {
        ...state,
        timeCreated: action.payload.timeCreated,
        dateCreated: action.payload.dateCreated,
      };
    case GET_TIME_CREATED_ERROR:
      return { ...state };
    case UPDATE_TIME_CREATED:
      return {
        ...state,
        timeCreated: action.payload.diffTime,
        dateCreated: action.payload.dateCreated,
        loading: false,
      };
    case DISABLE_CUSTOMER_EXIT:
      return { loadingCustomers: false };
    case DISABLE_CUSTOMER_ERROR:
      return { loadingCustomers: false };
    case GET_URL_REAL_EXIT:
      return { ...state };
    case GET_URL_REAL_ERROR:
      return { ...state };
    default:
      return { ...state };
  }
}

/* Actions (Calls API) */
export const getMenuData =
  ({ user = "", venue = "", qrFLow = "" }) =>
  async (dispatch, getState) => {
    // Init loading to create user
    dispatch({
      type: LOADING_MENU,
    });

    try {
      const p1 = new Promise((resolve) => {
        db.collection("users")
          .doc(user)
          .collection("venues")
          .doc(venue)
          .collection("qrFlows")
          .doc(qrFLow)
          .get()
          .then((res) => {
            resolve(res);
          });
      });
      const p2 = new Promise((resolve) => {
        db.collection("users")
          .doc(user)
          .collection("venues")
          .doc(venue)
          .get()
          .then((res) => {
            resolve(res);
          });
      });

      Promise.all([p1, p2]).then((values) => {
        const res = values[0];
        const resVenue = values[1];

        // Validate prices type
        let prices = 5;
        if (resVenue.data().pricesType) {
          prices = resVenue.data().pricesType;
        }

        // Validate show Ads
        let showAds = false;
        if (resVenue.data().showAds) {
          showAds = resVenue.data().showAds;
        }

        dispatch({
          type: GET_MENU_EXIT,
          payload: {
            ...res.data(),
            payNow: resVenue.data().payNow,
            pricesType: prices,
            showAds: showAds,
          },
        });
      });
    } catch (error) {
      const errorCode = error.code.split("/");
      const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

      // Save error
      openToast({
        content: <p> {errorMessage} </p>,
        type: ERROR,
        time: 6000,
      });

      if (errorMessage === "An unexpected error occurred, please try again") {
        analytics.logEvent("new_error", {
          description: `L145 @ scanDucks.js | ${error.code} - ${error.message}`,
        });
      } else {
        analytics.logEvent("new_error", {
          description: `L145 @ scanDucks.js | ${errorMessage}`,
        });
      }

      dispatch({
        type: GET_MENU_ERROR,
      });
    }
  };

export const addCustomer =
  ({ data = [], user = "", venue = "", qrFLow = "" }) =>
  async (dispatch, getState) => {
    // Init loading to create user
    dispatch({
      type: LOADING_MENU,
    });

    try {
      // Validate if the user exist
      const res = await db
        .collection("users")
        .doc(user)
        .collection("venues")
        .doc(venue)
        .collection("customers")
        .where("emailAddress", "==", data.emailAddress)
        .get();
      // Id if exist
      let idCustomer = null;
      let dateCreated = new Date();
      let diffInHours = null;

      res.forEach((customer) => {
        idCustomer = customer.id;
        // Validate if exist the user
        if (idCustomer) {
          dateCreated = new Date(customer.data().created.toDate());
        }
        return;
      });

      const newData = [];

      let hasFistName = true;
      let hasLastName = true;
      let hasEmail = true;
      let hasPhone = true;
      let hasBirthday = true;

      // Validate which fields exist
      if (!data.firstName.trim()) {
        hasFistName = false;
      } else {
        newData.firstName = data.firstName;
      }
      if (!data.lastName.trim()) {
        hasLastName = false;
      } else {
        newData.lastName = data.lastName;
      }
      if (!data.emailAddress.trim()) {
        hasEmail = false;
      } else {
        newData.emailAddress = data.emailAddress;
      }
      if (!data.phoneNumber.trim()) {
        hasPhone = false;
      } else {
        newData.phoneNumber = data.phoneNumber;
      }
      if (!data.birthday.trim()) {
        hasBirthday = false;
      } else {
        newData.birthday = data.birthday;
      }

      const { pricesType } = getState().scan.data;

      if (idCustomer && hasEmail) {
        analytics.logEvent("subscribe", {
          has_first_name: hasFistName,
          has_last_name: hasLastName,
          has_email: hasEmail,
          has_phone: hasPhone,
          has_birthday: hasBirthday,
          is_new: false,
          email: data.emailAddress,
          venue_id: venue,
          new_user_id: user,
          qr_flow_id: qrFLow,
          prices_type: pricesType,
          description: `${hasFistName} - ${hasLastName} - ${hasEmail} - ${hasPhone} - ${hasBirthday} - ${
            data.emailAddress
          } - ${venue} - ${false} - ${pricesType}`,
        });

        await db
          .collection("users")
          .doc(user)
          .collection("venues")
          .doc(venue)
          .collection("customers")
          .doc(idCustomer)
          .update({
            ...newData,
            activeEmail: "Subscribed",
            activeSMS: "Subscribed",
          });
      } else {
        analytics.logEvent("subscribe", {
          has_first_name: hasFistName,
          has_last_name: hasLastName,
          has_email: hasEmail,
          has_phone: hasPhone,
          has_birthday: hasBirthday,
          is_new: true,
          email: data.emailAddress,
          venue_id: venue,
          new_user_id: user,
          qr_flow_id: qrFLow,
          prices_type: pricesType,
          description: `${hasFistName} - ${hasLastName} - ${hasEmail} - ${hasPhone} - ${hasBirthday} - ${
            data.emailAddress
          } - ${venue} - ${true} - ${pricesType}`,
        });

        // Validate if some field exist
        if (hasFistName || hasLastName || hasEmail || hasPhone || hasBirthday) {
          await db
            .collection("users")
            .doc(user)
            .collection("venues")
            .doc(venue)
            .collection("customers")
            .add({
              ...data,
              activeEmail: "Subscribed",
              activeSMS: "Subscribed",
              qrFlowId: qrFLow,
              created: new Date(),
            });
        }
      }

      // Get the time that the user have subscribed to the restaurant
      diffInHours = DiffInHours(new Date(), dateCreated);

      dispatch({
        type: ADD_CUSTOMER_EXIT,
        payload: {
          diffTime: diffInHours,
          dateCreated: dateCreated,
        },
      });

      // Save in the browser
      localStorage.setItem(
        "scan",
        JSON.stringify({
          [user]: {
            timeCreated: diffInHours,
            dateCreated: dateCreated,
          },
        })
      );
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L317 @ scanDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: ADD_CUSTOMER_ERROR,
      });
    }
  };

export const getTimeCreated =
  ({ user = "" }) =>
  async (dispatch, getState) => {
    // Validate if the data exist in the browser
    if (localStorage.getItem("scan")) {
      if (JSON.parse(localStorage.getItem("scan"))[user]) {
        dispatch({
          type: GET_TIME_CREATED_EXIT,
          payload: {
            timeCreated: JSON.parse(localStorage.getItem("scan"))[user]
              .timeCreated,
            dateCreated: new Date(
              JSON.parse(localStorage.getItem("scan"))[user].dateCreated
            ),
          },
        });
        return;
      }
    }

    dispatch({
      type: GET_TIME_CREATED_ERROR,
    });
  };

export const updateTimeCreated =
  ({ user = "", diffInHours = null, dateCreated = null }) =>
  (dispatch, getState) => {
    dispatch({
      type: UPDATE_TIME_CREATED,
      payload: {
        diffTime: diffInHours,
        dateCreated: dateCreated,
      },
    });

    // Save in the browser
    localStorage.setItem(
      "scan",
      JSON.stringify({
        [user]: {
          timeCreated: diffInHours,
          dateCreated: dateCreated,
        },
      })
    );
  };

export const disableCustomer =
  ({ user = "", venue = "", email = "" }) =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING_CUSTOMERS_SCAN,
    });

    try {
      const res = await db
        .collection("users")
        .doc(user)
        .collection("venues")
        .doc(venue)
        .collection("customers")
        .where("emailAddress", "==", email)
        .get();
      const resVenue = await db
        .collection("users")
        .doc(user)
        .collection("venues")
        .doc(venue)
        .get();

      // Validate prices type
      let prices = 1;
      if (resVenue.data().pricesType) {
        prices = resVenue.data().pricesType;
      }

      if (res.docs.length > 0) {
        for await (let customers of res.docs) {
          await db
            .collection("users")
            .doc(user)
            .collection("venues")
            .doc(venue)
            .collection("customers")
            .doc(customers.id)
            .update({
              activeEmail: "Unsubscribed",
            });
        }

        openToast({
          content: <p> {i18n.t("toastSuccessUnsubscribed")} </p>,
          type: SUCCESS,
        });

        analytics.logEvent("toast", {
          description: `L423 @ scanDucks.jsx | You have been unsubscribed`,
        });

        analytics.logEvent("unsubscribe", {
          email: email,
          venue_id: venue,
          new_user_id: user,
          prices_type: prices,
          description: `${email} - ${venue} - ${prices}`,
        });

        dispatch({
          type: DISABLE_CUSTOMER_EXIT,
        });
      } else {
        openToast({
          content: <p> {i18n.t("toastErrorEmailNotFound")} </p>,
          type: ERROR,
        });

        analytics.logEvent("toast", {
          description: `L444 @ scanDucks.jsx | The email address was not found, please try again`,
        });

        dispatch({
          type: DISABLE_CUSTOMER_ERROR,
        });
      }
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L458 @ scanDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: DISABLE_CUSTOMER_ERROR,
      });
    }
  };

export const redirectFromCustomUrl =
  ({ urlId = "" }) =>
  async (dispatch, getState) => {
    try {
      const res = await db.collection("customUrls").doc(urlId).get();

      let url = res.data().url;

      if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }

      const userId = url.split("/")[5];
      const venueId = url.split("/")[6];
      const qrFlowId = url.split("/")[7];

      analytics.logEvent("open_custom_url", {
        new_user_id: userId,
        venue_id: venueId,
        qr_flow_id: qrFlowId,
        custom_url: urlId,
        description: `${urlId} - ${userId} - ${venueId} - ${qrFlowId}`,
      });

      window.location = url;

      dispatch({
        type: GET_URL_REAL_EXIT,
      });
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L503 @ scanDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: GET_URL_REAL_ERROR,
      });
    }
  };
