/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import { analytics } from "../controller/firebase";

import { openToast, ERROR } from "../constant/Toast";
import { GetScans } from "../helpers/GetScans";

import { CompareSameDates } from "../helpers/CompareSameDates";

import i18n from "i18next";

/* Constants or States */
const initData = {
    loading: true,
    scans: [],
};

const LOADING_SCANS = "LOADING_SCANS";

const GET_SEVEN_DAYS_SCANS_EXIT = "GET_SEVEN_DAYS_SCANS_EXIT";
const GET_SEVEN_DAYS_SCANS_ERROR = "GET_SEVEN_DAYS_SCANS_ERROR";

/* Reducer (Save call API in constant) */
export default function bigQueryReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_SCANS:
            return {...state, loading: true };
        case GET_SEVEN_DAYS_SCANS_EXIT:
            return {...state, scans: action.payload, loading: false };
        case GET_SEVEN_DAYS_SCANS_ERROR:
            return {...state, loading: false };
        default:
            return state;
    }
}

/* Actions (Calls API) */
export const getScans =
    ({ venue_id = "" }) =>
    async(dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_SCANS,
        });

        if (localStorage.getItem("bigQuery")) {
            // Get dates
            const lastDate = new Date(
                JSON.parse(localStorage.getItem("bigQuery")).lastDate
            );
            const todayDay = new Date();

            // Validate if is the same day
            const isSameDay = CompareSameDates({ date1: lastDate, date2: todayDay });

            // Validate if the last date is different to today date
            if (isSameDay) {
                dispatch({
                    type: GET_SEVEN_DAYS_SCANS_EXIT,
                    payload: [...JSON.parse(localStorage.getItem("bigQuery")).scans],
                });
                return;
            }
        }

        if (localStorage.getItem("auth")) {
            const uid = JSON.parse(localStorage.getItem("auth")).uid;

            try {
                const scans = await GetScans({ venue_id: venue_id });

                dispatch({
                    type: GET_SEVEN_DAYS_SCANS_EXIT,
                    payload: scans,
                });

                // Save in the browser
                localStorage.setItem(
                    "bigQuery",
                    JSON.stringify({
                        scans: scans,
                        lastDate: new Date(),
                    })
                );
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide");
                // Save analytics
                analytics.logEvent("new_error", {
                    description: `L79 @ bigQueryDucks.js | ${error.code} - ${error.message} - ${uid} -${venue_id}`,
                });

                dispatch({
                    type: GET_SEVEN_DAYS_SCANS_ERROR,
                });
            }
        }
    };