export function TimeConverter(timestamp) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    /* var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds(); */
    var time = date + ' ' + month + ' ' + year;
    return time;
}