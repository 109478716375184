import React, { Fragment } from "react";
import "./App.scss";

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { auth } from "./controller/firebase";
import Loading from "./components/loading/Loading";
import HelpButton from "./components/helpButton/HelpButton";

import { useSelector, useDispatch } from "react-redux";
import { logout } from "./redux/authDucks";

import "./i18n/i18n";
import FirebaseAnalytics from "components/firebaseAnalytics/FirebaseAnalytics";

const PageNotFound = React.lazy(() =>
  import("./pages/pageNotFound/PageNotFound")
);
const Home = React.lazy(() => import("./pages/home/Home"));
const Home2 = React.lazy(() => import("./pages/home/Home2"));
const Terms = React.lazy(() => import("./pages/terms/Terms"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Logout = React.lazy(() => import("./pages/logout/Logout"));
const DirectSignUp = React.lazy(() =>
  import("./components/directSignUp/DirectSignUp")
);
const Scan = React.lazy(() => import("./pages/scan/Scan"));
const CustomScan = React.lazy(() => import("./pages/customScan/CustomScan"));
const Unsubscribe = React.lazy(() => import("./pages/unsubscribe/Unsubscribe"));

const Footer = React.lazy(() => import("./components/footer/MyFooter"));
const TempToast = React.lazy(() => import("./components/tempToast/TempToast"));
const SideNav = React.lazy(() => import("./components/sideNav/SideNav"));
const SideContent = React.lazy(() =>
  import("./components/sideContent/SideContent")
);

function App() {
  // Const to call action to redux
  const dispatch = useDispatch();

  // Redux states
  const userId = useSelector((store) => store.auth.uid);

  // Component states
  const [firebaseUser, setFirebaseUser] = React.useState(false);

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged((user) => {
        if (user && user !== undefined) {
          setFirebaseUser(user);
        } else {
          setFirebaseUser(null);
        }
      });
    };

    fetchUser();
  }, []);

  const PrivateRoute = ({ component, path, ...rest }) => {
    // Validate if the user exist in the browser to enable the path
    if (localStorage.getItem("auth") && firebaseUser) {
      // If the user exist in the browser and is equals to the user got in firebase show the component
      const userBrowser = JSON.parse(localStorage.getItem("auth"));
      if (userBrowser.uid === firebaseUser.uid) {
        return <Route component={component} path={path} {...rest} />;
      } else {
        dispatch(logout());
        return <Redirect to="/login/signIn/home" {...rest} />;
      }
    } else {
      dispatch(logout());
      return <Redirect to="/login/signIn/home" {...rest} />;
    }
  };

  return firebaseUser !== false ? (
    <Router>
      <FirebaseAnalytics />
      <Switch>
        <Route path="/unsubscribe/:user/:venue/">
          <Unsubscribe />
        </Route>

        <Route path="/scan/:user/:venue/:qrFlow">
          <TempToast scanPage={true} />
          <Scan />
        </Route>

        <Route path="/qr/:url">
          <CustomScan />
        </Route>

        {/* Dashboard Page */}
        <PrivateRoute path={"/:user/dashboard"}>
          <Fragment>
            <TempToast />
            <div className="o-main">
              <SideNav />
              <SideContent />
            </div>
          </Fragment>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </PrivateRoute>

        {/* Old Dashboard Page */}
        <PrivateRoute path={"/dashboard/account/products"}>
          <Redirect to={`/${userId}/dashboard/account/products`} />
        </PrivateRoute>
        <PrivateRoute path={"/dashboard/account/invoices"}>
          <Redirect to={`/${userId}/dashboard/account/invoices`} />
        </PrivateRoute>
        <PrivateRoute path={"/dashboard/account"}>
          <Redirect to={`/${userId}/dashboard/account`} />
        </PrivateRoute>
        <PrivateRoute path={"/dashboard/marketing"}>
          <Redirect to={`/${userId}/dashboard/marketing`} />
        </PrivateRoute>
        <PrivateRoute path={"/dashboard"}>
          <Redirect to={"/:user/dashboard"} />
        </PrivateRoute>

        {/* Direct sign-up */}
        <Route path="/direct-signup/:country">
          <div className="o-page-footer">
            <DirectSignUp />
          </div>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </Route>

        {/* Login Page */}
        <Route path="/login/:type/:from/:country">
          <TempToast scanPage={true} />
          <div className="o-page-footer">
            <Login />
          </div>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </Route>

        <Route path="/login">
          <Redirect to="/login/signIn/home/US" />
        </Route>

        {/* Logout Page */}
        <Route path="/logout">
          <div className="o-page-footer">
            <Logout />
          </div>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </Route>

        {/* Terms Page */}
        <Route path="/terms">
          <div className="o-page-footer">
            <Terms />
            <Footer />
          </div>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </Route>

        {/* Landing page */}
        <Route path="/home">
          <Redirect to="/US" />
        </Route>

        <Route path="/home-2/:country">
          <Fragment>
            <Home2 />
            <Footer />
          </Fragment>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </Route>

        <Route path="/:country" exact>
          <Fragment>
            <Home />
            <Footer />
          </Fragment>
          <div className="o-container-help-btn">
            <HelpButton />
          </div>
        </Route>

        <Route path="/" exact>
          <Redirect to="/US" />
        </Route>

        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  ) : (
    <Loading fullHeight={true} />
  );
}

export default App;
