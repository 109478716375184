import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { analytics } from "controller/firebase";

function FirebaseAnalytics() {
  let location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    analytics.setCurrentScreen(page_path);
    analytics.logEvent("page_view", { page_path });

    // Validate the page
    const isMenu = page_path.includes("menus");
    const isCode = page_path.includes("menus/code");
    const isReport = page_path.includes("menus/reports");

    const isAccount = page_path.includes("account");
    const isInvoice = page_path.includes("account/invoice");
    const isProducts = page_path.includes("account/products");

    const isSignUp = page_path.includes("signUp");

    if (isReport) {
      analytics.logEvent("reports_visit");
    } else if (isCode) {
      analytics.logEvent("code_visit");
    } else if (isMenu) {
      analytics.logEvent("menu_visit");
    }

    if (isProducts) {
      analytics.logEvent("products_visit");
    } else if (isInvoice) {
      analytics.logEvent("invoices_visit");
    } else if (isAccount) {
      analytics.logEvent("account_visit");
    }

    if (isSignUp) {
      analytics.logEvent("sign_up_visit");
    }
  }, [location]);
  return null;
}

export default FirebaseAnalytics;
