/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import {
  db,
  storage,
  analytics,
  functions,
  auth,
} from "../controller/firebase";
import {
  CreateQRCodeFast,
  CreateQrCodeWithLogoFast,
} from "../helpers/CreateQrCode";
import { openToast, ERROR } from "../constant/Toast";

import { getQrFlowsData } from "./qrFlowDucks";
import { CreateCustomUrl } from "../helpers/CreateCustomUrl";

import i18n from "i18next";

/* Constants or States */
const initData = {
  loading: false,
  loadingQrs: false,
  newUser: false,
  data: [],
};

const LOADING_USER = "LOADING_USER";
const LOADING_QRS = "LOADING_QRS";

const CREATE_USER_EXIT = "CREATE_USER_EXIT";
const CREATE_USER_ERROR = "CREATE_USER_ERROR";

const CREATE_QRS_EXIT = "CREATE_QRS_EXIT";
const CREATE_QRS_ERROR = "CREATE_QRS_ERROR";

const GET_USER_EXIT = "GET_USER_EXIT";
const GET_USER_ERROR = "GET_USER_ERROR";

const UPDATE_USER_EXIT = "UPDATE_USER_EXIT";
const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

const RESTART_USER = "RESTART_USER";

/* Reducer (Save call API in constant) */
export default function authReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_USER:
      return { ...state, loading: true };
    case LOADING_QRS:
      return { ...state, loadingQrs: true };
    case CREATE_USER_EXIT:
      return {
        ...state,
        data: { ...action.payload },
        newUser: true,
        loading: false,
      };
    case CREATE_USER_ERROR:
      return { ...initData };
    case CREATE_QRS_EXIT:
      return { ...state, loadingQrs: false };
    case CREATE_QRS_ERROR:
      return { ...initData };
    case GET_USER_EXIT:
      return { ...state, data: { ...action.payload }, loading: false };
    case GET_USER_ERROR:
      return { ...initData };
    case UPDATE_USER_EXIT:
      return { ...state, data: { ...action.payload }, loading: false };
    case UPDATE_USER_ERROR:
      return { ...state, loading: false };
    case RESTART_USER:
      return { ...initData };
    default:
      return { ...state };
  }
}

/* Actions (Calls API) */
export const createUserStructure =
  ({ uid = "", email = "", country = "US", from = "home" }) =>
  async (dispatch) => {
    // Init loading to create user
    dispatch({
      type: LOADING_USER,
    });

    let idVenue = "";
    let idFlow = "";

    try {
      // Get Time zone from the browser
      const time = new Date().toTimeString().slice(9);
      const timezone =
        time.substr(0, 6) +
        ":" +
        time.substr(6, 3) +
        Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Get language
      const lang = navigator.language || navigator.userLanguage;

      // User's properties
      await db
        .collection("users")
        .doc(uid)
        .set({
          firstName: "",
          lastName: "",
          email: email,
          phone: "",
          lang: lang,
          timezone: timezone,
          pricesType: 6,
          country: country,
          from: from,
          usage: {
            contacts: 100,
            emails_1: 100,
            created: new Date(),
          },
          reloadData: false,
          toast: {
            plan: false,
            customers: false,
          },
        });

      // Create venue
      const venue = await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .add({
          name: "",
          address: {
            street: "",
            country: "",
            city: "",
            state: "",
            postalCode: "",
          },
          socials: {
            instagram: "",
            tiktok: "",
            twitter: "",
            facebook: "",
          },
          payNow: false,
          pricesType: 6,
          showAds: true,
        });

      // Create QR flow
      const qrFlow = await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .doc(venue.id)
        .collection("qrFlows")
        .add({
          name: `Menu 1 - ${i18n.t("menuWord")} 1`,
          qrCode: {
            config: {
              body: "dot",
              eye: "frame1",
              eyeBall: "ball1",
              erf1: ["fh"],
              brf1: ["fh"],
              erf3: ["fv", "fh"],
              brf3: ["fv", "fh"],
              bodyColor: "#000",
              bgColor: "#fff",
              eye1Color: "#000",
              eye2Color: "#000",
              eye3Color: "#000",
              eyeBall1Color: "#000",
              eyeBall2Color: "#000",
              eyeBall3Color: "#000",
            },
            style: "normal",
            logo: null,
            options: {
              noLogo: null,
              defaultLogo: null,
              customLogo: null,
            },
            selectedOption: "defaultLogo",
          },
          flowSequence: {
            newsLetterSignUp: {
              active: false,
              text: "Subscribe to our newsletter",
              firstName: false,
              lastName: false,
              emailAddress: true,
              phoneNumber: false,
              birthday: false,
              image: null,
              answerYes: "Subscribe",
              answerNo: "No, thanks",
            },
            showMenu: [],
          },
          sequence: ["newsLetterSignUp", "showMenu"],
          customUrl: "",
        });

      idVenue = venue.id;
      idFlow = qrFlow.id;

      // Create customs URL
      const customUrl = await CreateCustomUrl({
        userId: uid,
        venueId: idVenue,
        qrFlowId: idFlow,
      });

      // Update in DataBase
      db.collection("users")
        .doc(uid)
        .collection("venues")
        .doc(idVenue)
        .collection("qrFlows")
        .doc(idFlow)
        .update({
          customUrl: customUrl,
        });

      // Create QR codes
      createQRs({
        idVenue: idVenue,
        idQrFlow: idFlow,
        uid: uid,
        email: email,
        lang: lang,
        pricesType: 6,
      })(dispatch);

      // Send welcome email
      const sendWelcomeEmail = functions.httpsCallable("sendWelcomeEmail");
      sendWelcomeEmail({ email: email, language: lang });

      // Date end free trial
      const endTrial = new Date();
      endTrial.setDate(endTrial.getDate() + 14);

      dispatch({
        type: CREATE_USER_EXIT,
        payload: {
          uid: uid,
          email: email,
          firstName: "",
          lastName: "",
          phone: "",
          timezone: timezone,
          lang: lang,
          pricesType: 6,
          country: country,
          from: from,
          usage: {
            contacts: 100,
            emails_1: 100,
            created: new Date(),
          },
          reloadData: false,
          toast: {
            plan: false,
            customers: false,
          },
        },
      });

      // Save in the browser user data
      localStorage.setItem(
        "user",
        JSON.stringify({
          uid: uid,
          email: email,
          firstName: "",
          lastName: "",
          phone: "",
          timezone: timezone,
          lang: lang,
          pricesType: 6,
          country: country,
          from: from,
          usage: {
            contacts: 100,
            emails_1: 100,
            created: new Date(),
          },
          reloadData: false,
          toast: {
            plan: false,
            customers: false,
          },
        })
      );

      // Save in the browser venue data
      localStorage.setItem(
        "venue",
        JSON.stringify({
          data: [
            {
              name: "",
              address: {
                street: "",
                country: "",
                city: "",
                state: "",
                postalCode: "",
              },
              socials: {
                instagram: "",
                tiktok: "",
                twitter: "",
                facebook: "",
              },
              payNow: false,
              pricesType: 6,
              showAds: true,
              id: idVenue,
            },
          ],
        })
      );

      // Save in the browser qrFlow data
      localStorage.setItem(
        "qrFlow",
        JSON.stringify({
          data: [
            {
              name: `Menu 1 - ${i18n.t("menuWord")} 1`,
              qrCode: {
                config: {
                  body: "dot",
                  eye: "frame1",
                  eyeBall: "ball1",
                  erf1: ["fh"],
                  brf1: ["fh"],
                  erf3: ["fv", "fh"],
                  brf3: ["fv", "fh"],
                  bodyColor: "#000",
                  bgColor: "#fff",
                  eye1Color: "#000",
                  eye2Color: "#000",
                  eye3Color: "#000",
                  eyeBall1Color: "#000",
                  eyeBall2Color: "#000",
                  eyeBall3Color: "#000",
                },
                style: "normal",
                logo: null,
                options: {
                  noLogo: null,
                  defaultLogo: null,
                  customLogo: null,
                },
                selectedOption: "defaultLogo",
              },
              flowSequence: {
                newsLetterSignUp: {
                  active: false,
                  text: "Subscribe to our newsletter",
                  firstName: false,
                  lastName: false,
                  emailAddress: true,
                  phoneNumber: false,
                  birthday: false,
                  image: null,
                  answerYes: "Subscribe",
                  answerNo: "No, thanks",
                },
                showMenu: [],
              },
              sequence: ["newsLetterSignUp", "showMenu"],
              customUrl: customUrl,
              idVenue: idVenue,
              idQrFlow: idFlow,
            },
          ],
        })
      );
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L245 @ userDucks.js | ${error.code} - ${error.message}`,
      });

      // Delete structure
      await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .doc(idVenue)
        .collection("qrFlows")
        .doc(idFlow)
        .delete();

      dispatch({
        type: CREATE_USER_ERROR,
      });
    }
  };

export const createQRs =
  ({
    uid = "",
    idVenue = "",
    idQrFlow = "",
    email = "",
    lang = "",
    pricesType = 6,
  }) =>
  async (dispatch) => {
    dispatch({
      type: LOADING_QRS,
    });

    try {
      // Create QR codes
      const qrNoLogo = await CreateQRCodeFast({
        url: `${window.origin}/#/scan/${uid}/${idVenue}/${idQrFlow}`,
        pricesType: pricesType,
        userId: uid,
        venueId: idVenue,
        qrFlowId: idQrFlow,
      });

      // Save image in the storage
      const metaData = { contentType: qrNoLogo.file.type };
      const res = await storage
        .ref()
        .child(uid + "/" + idVenue + "/" + idQrFlow + "/QR/")
        .child("noLogo.png")
        .put(qrNoLogo.file, metaData);
      const qrNoLogoURL = await res.ref.getDownloadURL();

      const qrDefaultLogo = await CreateQrCodeWithLogoFast({
        url: `${window.origin}/#/scan/${uid}/${idVenue}/${idQrFlow}`,
        pricesType: pricesType,
        userId: uid,
        venueId: idVenue,
        qrFlowId: idQrFlow,
      });
      // Save image in the storage
      const metaDataQrDefaultLogo = { contentType: qrDefaultLogo.file.type };
      const resQrDefaultLogoURL = await storage
        .ref()
        .child(uid + "/" + idVenue + "/" + idQrFlow + "/QR/")
        .child("defaultLogo.png")
        .put(qrDefaultLogo.file, metaDataQrDefaultLogo);
      const qrDefaultLogoURL = await resQrDefaultLogoURL.ref.getDownloadURL();

      // Update data base
      await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .doc(idVenue)
        .collection("qrFlows")
        .doc(idQrFlow)
        .update({
          "qrCode.options.noLogo": qrNoLogoURL,
          "qrCode.options.defaultLogo": qrDefaultLogoURL,
        });

      // Create reminder
      await db.collection("emailReminder").doc().set({
        idUser: uid,
        dateCreated: new Date(),
        email: email,
        language: lang,
        idVenue: idVenue,
        idQrFlow: idQrFlow,
      });

      // Update redux state
      getQrFlowsData({ readDB: true })(dispatch);

      dispatch({
        type: CREATE_QRS_EXIT,
      });
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L314 @ userDucks.js | ${error.code} - ${error.message}`,
      });

      // Delete structure
      await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .doc(idVenue)
        .collection("qrFlows")
        .doc(idQrFlow)
        .delete();

      dispatch({
        type: CREATE_QRS_ERROR,
      });
    }
  };

export const getDataUser = () => async (dispatch) => {
  // Init loading to create user
  dispatch({
    type: LOADING_USER,
  });

  // Validate if the data exist in the browser
  if (localStorage.getItem("user")) {
    dispatch({
      type: GET_USER_EXIT,
      payload: JSON.parse(localStorage.getItem("user")),
    });
    return;
  }

  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;
    let data = {};

    try {
      const res = await db.collection("users").doc(uid).get();

      // Validate if pricesType exist
      let prices = 1;
      if (res.data().pricesType) {
        prices = res.data().pricesType;
      }

      data = {
        ...data,
        email: res.data().email,
        firstName: res.data().firstName,
        lastName: res.data().lastName,
        phone: res.data().phone,
        timezone: res.data().timezone,
        lang: res.data().lang,
        pricesType: prices,
        country: res.data().country,
        from: res.data().from,
        usage: {
          ...res.data().usage,
          created: new Date(res.data().usage.created.toDate()),
        },
        reloadData: res.data().reloadData,
        toast: res.data().toast,
      };

      dispatch({
        type: GET_USER_EXIT,
        payload: data,
      });

      // Save in the browser
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...data,
        })
      );
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L383 @ userDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: GET_USER_ERROR,
      });
    }
  }
};

export const updateDataUser =
  ({ data = {}, newEmail = null }) =>
  async (dispatch) => {
    // Init loading to create user
    dispatch({
      type: LOADING_USER,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      let email = "";

      if (newEmail) {
        email = newEmail;
      } else {
        email = JSON.parse(localStorage.getItem("auth")).email;
      }

      try {
        await db.collection("users").doc(uid).update({
          firstName: data.firstName,
          lastName: data.lastName,
          email: email,
          phone: data.phone,
        });

        // Reload data token
        reloadDataToken()(dispatch);

        dispatch({
          type: UPDATE_USER_EXIT,
          payload: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: email,
            phone: data.phone,
          },
        });

        // Save in the browser
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: uid,
            email: email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
          })
        );
      } catch (error) {
        console.log(error);
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L441 @ userDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_USER_ERROR,
        });
      }
    }
  };

export const updateTimezoneUser =
  ({ data = {} }) =>
  async (dispatch, getState) => {
    // Init loading to create user
    dispatch({
      type: LOADING_USER,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;

      try {
        await db.collection("users").doc(uid).update({
          timezone: data.timezone,
        });

        // Reload data token
        reloadDataToken()(dispatch);

        dispatch({
          type: UPDATE_USER_EXIT,
          payload: {
            ...getState().user.data,
            timezone: data.timezone,
          },
        });

        // Save in the browser
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...getState().user.data,
            timezone: data.timezone,
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L484 @ userDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_USER_ERROR,
        });
      }
    }
  };

// Save reload data
export const reloadDataToken =
  (reload = true) =>
  async () => {
    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      try {
        // Get current tokenID
        const idToken = await auth.currentUser.getIdToken(
          /* forceRefresh */
          false
        );
        // Save current tokenID
        await db.collection("users").doc(uid).set(
          {
            idToken,
          },
          { merge: true }
        );

        await db.collection("users").doc(uid).set(
          {
            reloadDataToken: reload,
          },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

// Reset toast
export const restartDataUser = () => async (dispatch) => {
  dispatch({
    type: RESTART_USER,
  });
};
