/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import {
  db,
  firebase,
  storage,
  analytics,
  functions,
} from "../controller/firebase";
import { CreateRandomId } from "../helpers/CreateRandomId";

import { openToast, ERROR, SUCCESS } from "../constant/Toast";

import { GetFileSizeFromURL } from "../helpers/GetFileSizeFromURL";
import {
  CreateQRCodeFast,
  CreateQrCodeWithLogoFast,
} from "../helpers/CreateQrCode";
import { CreateCustomUrl } from "../helpers/CreateCustomUrl";

import i18n from "i18next";
import { ConvertUrlToBase64 } from "helpers/ConvertUrlToBase64";
import { reloadDataToken } from "./userDucks";

/* Constants or States */
const initData = {
  loading: true,
  loadingMenu: false,
  loadingCustomers: false,
  loadingQr: false,
  flowSelected: 0,
  flows: [{}],
  newImagesAdding: false,
  numNewImagesUploaded: 0,
  totalNewImagesUploaded: 0,
};

const LOADING_QR_FLOW = "LOADING_QR_FLOW";
const LOADING_QR_FLOW_MENU = "LOADING_QR_FLOW_MENU";
const LOADING_QR_FLOW_CUSTOMERS = "LOADING_QR_FLOW_CUSTOMERS";
const LOADING_QR_FLOW_QR = "LOADING_QR_FLOW_QR";

const START_LOADING_FLOW_MENU = "START_LOADING_FLOW_MENU";
const END_LOADING_FLOW_MENU = "END_LOADING_FLOW_MENU";

const CHANGE_QR_FLOW_SELECTED = "CHANGE_QR_FLOW_SELECTED";

const GET_QR_FLOW_EXIT = "GET_QR_FLOW_EXIT";
const GET_QR_FLOW_ERROR = "GET_QR_FLOW_ERROR";

const UPDATE_IMAGES_QR_FLOW_EXIT = "UPDATE_IMAGES_QR_FLOW_EXIT";
const UPDATE_IMAGES_QR_FLOW_ERROR = "UPDATE_IMAGES_QR_FLOW_ERROR";

const UPDATE_IMAGES_ADDING = "UPDATE_IMAGES_ADDING";

const SORT_IMAGES_QR_FLOW_EXIT = "SORT_IMAGES_QR_FLOW_EXIT";
const SORT_IMAGES_QR_FLOW_ERROR = "SORT_IMAGES_QR_FLOW_ERROR";

const DELETE_IMAGES_QR_FLOW_EXIT = "DELETE_IMAGES_QR_FLOW_EXIT";
const DELETE_IMAGES_QR_FLOW_ERROR = "DELETE_IMAGES_QR_FLOW_ERROR";

const START_LOADING_QR_FLOW = "START_LOADING_QR_FLOW";
const END_LOADING_QR_FLOW = "END_LOADING_QR_FLOW";

const CREATE_QR_FLOW_EXIT = "CREATE_QR_FLOW_EXIT";
const CREATE_QR_FLOW_ERROR = "CREATE_QR_FLOW_ERROR";

const DELETE_QR_FLOW_EXIT = "DELETE_QR_FLOW_EXIT";
const DELETE_QR_FLOW_ERROR = "DELETE_QR_FLOW_ERROR";

const UPDATE_QR_FLOW_EXIT = "UPDATE_QR_FLOW_EXIT";
const UPDATE_QR_FLOW_ERROR = "UPDATE_QR_FLOW_ERROR";

const UPDATE_WELCOME_QR_FLOW_EXIT = "UPDATE_WELCOME_QR_FLOW_EXIT";
const UPDATE_WELCOME_QR_FLOW_ERROR = "UPDATE_WELCOME_QR_FLOW_ERROR";

const UPDATE_SIGN_UP_QR_FLOW_EXIT = "UPDATE_SIGN_UP_QR_FLOW_EXIT";
const UPDATE_SIGN_UP_QR_FLOW_ERROR = "UPDATE_SIGN_UP_QR_FLOW_ERROR";

const UPDATE_QR_SELECTED_EXIT = "UPDATE_QR_SELECTED_EXIT";
const UPDATE_QR_SELECTED_ERROR = "UPDATE_QR_SELECTED_ERROR";

const UPDATE_CUSTOM_URL_EXIT = "UPDATE_CUSTOM_URL_EXIT";
const UPDATE_CUSTOM_URL_ERROR = "UPDATE_CUSTOM_URL_ERROR";

const CREATE_QR_CUSTOM_LOGO_EXIT = "CREATE_QR_CUSTOM_LOGO_EXIT";
const CREATE_QR_CUSTOM_LOGO_ERROR = "CREATE_QR_CUSTOM_LOGO_ERROR";

const DELETE_QR_CUSTOM_LOGO_EXIT = "DELETE_QR_CUSTOM_LOGO_EXIT";
const DELETE_QR_CUSTOM_LOGO_ERROR = "DELETE_QR_CUSTOM_LOGO_ERROR";

const RESTART_QR_FLOW = "RESTART_QR_FLOW";

/* Reducer (Save call API in constant) */
export default function qrFlowReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_QR_FLOW:
      return { ...state, loading: true };
    case LOADING_QR_FLOW_MENU:
      return { ...state, loadingMenu: true };
    case LOADING_QR_FLOW_CUSTOMERS:
      return { ...state, loadingCustomers: true };
    case LOADING_QR_FLOW_QR:
      return { ...state, loadingQr: true };

    case START_LOADING_FLOW_MENU:
      return { ...state, loadingMenu: true };
    case END_LOADING_FLOW_MENU:
      return { ...state, loadingMenu: false };

    case CHANGE_QR_FLOW_SELECTED:
      return { ...state, flowSelected: action.payload };

    case GET_QR_FLOW_EXIT:
      return { ...state, flows: [...action.payload], loading: false };
    case GET_QR_FLOW_ERROR:
      return { ...initData };

    case UPDATE_IMAGES_QR_FLOW_EXIT:
      return {
        ...state,
        flows: [...action.payload],
        loadingMenu: false,
        newImagesAdding: false,
        numNewImagesUploaded: 0,
      };
    case UPDATE_IMAGES_QR_FLOW_ERROR:
      return { ...state, loadingMenu: false };
    case UPDATE_IMAGES_ADDING:
      return {
        ...state,
        newImagesAdding: true,
        numNewImagesUploaded: action.payload.numImage,
        totalNewImagesUploaded: action.payload.totalImages,
      };
    case SORT_IMAGES_QR_FLOW_EXIT:
      return { ...state, flows: [...action.payload], loadingMenu: false };
    case SORT_IMAGES_QR_FLOW_ERROR:
      return { ...state, loadingMenu: false };
    case DELETE_IMAGES_QR_FLOW_EXIT:
      return { ...state, flows: [...action.payload], loadingMenu: false };
    case DELETE_IMAGES_QR_FLOW_ERROR:
      return { ...state, loadingMenu: false };

    case UPDATE_WELCOME_QR_FLOW_EXIT:
      return { ...state, flows: [...action.payload], loadingCustomers: false };
    case UPDATE_WELCOME_QR_FLOW_ERROR:
      return { ...state, loadingCustomers: false };

    case UPDATE_SIGN_UP_QR_FLOW_EXIT:
      return { ...state, flows: [...action.payload], loadingCustomers: false };
    case UPDATE_SIGN_UP_QR_FLOW_ERROR:
      return { ...state, loadingCustomers: false };

    case UPDATE_CUSTOM_URL_EXIT:
      return { ...state, flows: [...action.payload], loading: false };
    case UPDATE_CUSTOM_URL_ERROR:
      return { ...state, loading: false };

    case START_LOADING_QR_FLOW:
      return { ...state, loadingQr: true };
    case END_LOADING_QR_FLOW:
      return { ...state, loadingQr: false };

    case UPDATE_QR_SELECTED_EXIT:
      return { ...state, flows: [...action.payload], loadingQr: false };
    case UPDATE_QR_SELECTED_ERROR:
      return { ...state, loadingQr: false };
    case CREATE_QR_CUSTOM_LOGO_EXIT:
      return { ...state, flows: [...action.payload], loadingQr: false };
    case CREATE_QR_CUSTOM_LOGO_ERROR:
      return { ...state, loadingQr: false };
    case DELETE_QR_CUSTOM_LOGO_EXIT:
      return { ...state, flows: [...action.payload], loadingQr: false };
    case DELETE_QR_CUSTOM_LOGO_ERROR:
      return { ...state, loadingQr: false };

    case CREATE_QR_FLOW_EXIT:
      return {
        ...state,
        flows: [...action.payload.flow],
        loading: false,
        flowSelected: action.payload.index,
      };
    case CREATE_QR_FLOW_ERROR:
      return { ...state, loading: false };
    case DELETE_QR_FLOW_EXIT:
      return {
        ...state,
        flows: [...action.payload],
        loading: false,
        flowSelected: 0,
      };
    case DELETE_QR_FLOW_ERROR:
      return { ...state, loading: false };
    case UPDATE_QR_FLOW_EXIT:
      return { ...state, flows: [...action.payload], loading: false };
    case UPDATE_QR_FLOW_ERROR:
      return { ...state, loading: false };

    case RESTART_QR_FLOW:
      return { ...initData };
    default:
      return { ...state };
  }
}

/* Actions (Calls API) */

export const getQrFlowsData =
  ({ readDB = false }) =>
  async (dispatch) => {
    // Init loading to create user
    if (!readDB) {
      dispatch({
        type: LOADING_QR_FLOW,
      });
    }

    // Validate if the data exist in the browser
    if (localStorage.getItem("qrFlow") && !readDB) {
      dispatch({
        type: GET_QR_FLOW_EXIT,
        payload: JSON.parse(localStorage.getItem("qrFlow")).data,
      });
      return;
    }

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      let data = [];

      try {
        const res = await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .get();

        for await (let venue of res.docs) {
          // Get id to each venue
          const res = await db
            .collection("users")
            .doc(uid)
            .collection("venues")
            .doc(venue.id)
            .collection("qrFlows")
            .orderBy("name")
            .get();
          res.forEach(async (qrFlow) => {
            data = [
              ...data,
              {
                ...qrFlow.data(),
                idVenue: venue.id,
                idQrFlow: qrFlow.id,
              },
            ];
          });
        }

        // Convert logos into base64
        for (let i in data) {
          const qrFlow = data[i];
          if (qrFlow.qrCode.logo) {
            const logoBase64 = await ConvertUrlToBase64(qrFlow.qrCode.logo);
            data[i].qrCode.logo = logoBase64;
          }
        }

        if (data.length > 0) { 
          dispatch({
            type: GET_QR_FLOW_EXIT,
            payload: data,
          });

          // Save in the browser
          localStorage.setItem(
            "qrFlow",
            JSON.stringify({
              data,
            })
          );
        }
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L261 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: GET_QR_FLOW_ERROR,
        });
      }
    }
  };

export const changeQrFlowSelected =
  ({ index = 0 }) =>
  (dispatch, getState) => {
    dispatch({
      type: CHANGE_QR_FLOW_SELECTED,
      payload: index,
    });
  };

/* Menus */

export const addImagesQrFlow =
  ({ data = {}, images = [] }) =>
  async (dispatch, getState) => {
    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      let newImages = [];
      let newImagesAnalytics = [];

      try {
        // Read all image to add in data base
        for (let i = 0; i < images.length; i++) {
          const image = images[i];

          // Create random ID to the image
          let randomID = CreateRandomId(20);

          // Update percentage images
          dispatch({
            type: UPDATE_IMAGES_ADDING,
            payload: {
              numImage: i + 1,
              totalImages: images.length,
            },
          });

          if (data.flowSequence.showMenu.length !== undefined) {
            // Validate if the randomID exist in another file
            let imageSave = data.flowSequence.showMenu.find(
              (element) => element.storageName === randomID
            );
            while (imageSave !== undefined) {
              randomID = CreateRandomId(20);
              imageSave = data.flowSequence.showMenu.find(
                (element) => element.storageName === randomID
              );
            }
          }

          // Save image in the storage
          const metaData = { contentType: image.type };
          const res = await storage
            .ref()
            .child(uid + "/" + data.idVenue + "/" + data.idQrFlow + "/images/")
            .child(randomID + ".png")
            .put(image, metaData);
          const imageURL = await res.ref.getDownloadURL();

          // Update data base
          await db
            .collection("users")
            .doc(uid)
            .collection("venues")
            .doc(data.idVenue)
            .collection("qrFlows")
            .doc(data.idQrFlow)
            .update({
              "flowSequence.showMenu": firebase.firestore.FieldValue.arrayUnion(
                { url: imageURL, id: randomID }
              ),
            });

          newImages = [...newImages, { url: imageURL, id: randomID }];
          newImagesAnalytics.push({ url: imageURL, image: image });
        }

        // Reload data token
        reloadDataToken()(dispatch);

        // Create new flow with the showMenu updated
        //const flow = {...data, flowSequence: {...data.flowSequence, showMenu: [...data.flowSequence.showMenu, ...newImages] } }

        // Update the flow with the current flow
        const { flows } = getState().qrFlow;
        const newFlow = flows.map((flow) =>
          flow.idQrFlow === data.idQrFlow
            ? {
                ...flow,
                flowSequence: {
                  ...flow.flowSequence,
                  showMenu: [...flow.flowSequence.showMenu, ...newImages],
                },
              }
            : { ...flow }
        );

        dispatch({
          type: UPDATE_IMAGES_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );

        // Save analytics
        for (let i = 0; i < newImagesAnalytics.length; i++) {
          const image = newImagesAnalytics[i].image;
          const imageURL = newImagesAnalytics[i].url;

          // Save the size in Kilobytes
          const sizeKbBefore = (image.size / 1024).toFixed(1);

          // Get image info from url
          const httpsReference = storage.refFromURL(imageURL);
          const nameAfter = httpsReference.name;
          const formatAfter = httpsReference.name.split(".")[1];
          let sizeKbAfter = 0;
          await GetFileSizeFromURL(imageURL, function (size) {
            sizeKbAfter = (size / 1024).toFixed(1);
          });

          // Validate formats
          let weirdFormat = false;
          if (
            formatAfter !== "png" ||
            (image.type !== "image/png" &&
              image.type !== "image/jpg" &&
              image.type !== "image/jpeg")
          ) {
            weirdFormat = true;
          }
          // Validate size
          let sizeDiff = (
            ((sizeKbAfter - sizeKbBefore) / sizeKbBefore) *
            100
          ).toFixed(0);

          // Create descriptions with all parameters
          const imageDescription = `${image.type}-${sizeKbBefore} + ${uid}-${nameAfter}-${formatAfter}-${sizeKbAfter} + ${weirdFormat}-${sizeDiff}`;
          const pathDescription = `${uid}-${data.idVenue}-${data.idQrFlow} + ${weirdFormat}-${sizeDiff}`;

          analytics.logEvent("menu_file_uploaded", {
            format_before: image.type,
            name_before: image.name,
            size_before: sizeKbBefore,

            format_after: formatAfter,
            name_after: nameAfter,
            size_after: sizeKbAfter,

            weird_format: weirdFormat,
            size_diff: sizeDiff,

            new_user_id: uid,
            venue_id: data.idVenue,
            qr_flow_id: data.idQrFlow,

            image_description: imageDescription,
            path_description: pathDescription,
          });
        }
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L423 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_IMAGES_QR_FLOW_ERROR,
        });
      }
    }
  };

export const updateImageAdding = () => (dispatch) => {
  dispatch({
    type: UPDATE_IMAGES_ADDING,
    payload: {
      numImage: 0,
      totalImages: 0,
    },
  });
};

export const sortImagesQrFlow =
  ({ images = {} }) =>
  async (dispatch, getState) => {
    // Init loading to create user
    dispatch({
      type: LOADING_QR_FLOW_MENU,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;
      const { flowSelected } = getState().qrFlow;

      try {
        const data = flows[flowSelected];

        // update from data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "flowSequence.showMenu": images,
          });

        // Reload data token
        reloadDataToken()(dispatch);

        const flow = {
          ...data,
          flowSequence: { ...data.flowSequence, showMenu: images },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: SORT_IMAGES_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L491 @ qrFlowDucks.js | ${error.code} - ${error.message} - uid:${uid}`,
        });

        dispatch({
          type: SORT_IMAGES_QR_FLOW_ERROR,
        });
      }
    }
  };

export const deleteImageQrFlow =
  ({ data = {}, idImage }) =>
  async (dispatch, getState) => {
    // Init loading to create user
    dispatch({
      type: LOADING_QR_FLOW_MENU,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Get deleted image
        const deletedImage = data.flowSequence.showMenu.find(
          (item) => item.id === idImage
        );

        // Get image info from url
        const httpsReference = storage.refFromURL(deletedImage.url);
        const imageDeletedFormat = httpsReference.name.split(".")[1];
        let imageDeletedSizeKb = 0;
        await GetFileSizeFromURL(deletedImage.url, function (size) {
          imageDeletedSizeKb = (size / 1024).toFixed(1);
        });

        // Create descriptions with all parameters
        const imageDescription = `${uid} + ${deletedImage.id}-${imageDeletedSizeKb}-${imageDeletedFormat}`;
        const pathDescription = `${uid}-${data.idVenue}-${data.idQrFlow}`;

        analytics.logEvent("delete_thumbnail", {
          new_user_id: uid,
          venue_id: data.idVenue,
          qr_flow_id: data.idQrFlow,

          file_name: deletedImage.id,
          file_size: imageDeletedSizeKb,
          file_format: imageDeletedFormat,

          image_description: imageDescription,
          path_description: pathDescription,
        });

        // Delete image in the index send
        const images = data.flowSequence.showMenu.filter(
          (item, index) => item.id !== idImage
        );

        // Delete from the storage
        await storage
          .ref()
          .child(uid + "/" + data.idVenue + "/" + data.idQrFlow + "/images/")
          .child(idImage + ".png")
          .delete();

        // Delete from data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "flowSequence.showMenu": images,
          });

        // Reload data token
        reloadDataToken()(dispatch);

        const flow = {
          ...data,
          flowSequence: { ...data.flowSequence, showMenu: images },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: DELETE_IMAGES_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L568 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: DELETE_IMAGES_QR_FLOW_ERROR,
        });
      }
    }
  };

export const starMenuLoading = () => async (dispatch) => {
  dispatch({
    type: START_LOADING_FLOW_MENU,
  });
};

export const endMenuLoading = () => async (dispatch) => {
  dispatch({
    type: END_LOADING_FLOW_MENU,
  });
};

/* Customers */

export const updateWelcomeQrFlow =
  ({ data = {}, welcomeData = {} }) =>
  async (dispatch, getState) => {
    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;
      let flow;

      try {
        if (welcomeData.file) {
          // Init loading
          dispatch({
            type: LOADING_QR_FLOW_CUSTOMERS,
          });

          if (welcomeData.file === "DELETE") {
            // Update data base
            await db
              .collection("users")
              .doc(uid)
              .collection("venues")
              .doc(data.idVenue)
              .collection("qrFlows")
              .doc(data.idQrFlow)
              .update({
                "flowSequence.newsLetterWelcome": {
                  ...data.flowSequence.newsLetterWelcome,
                  image: null,
                },
              });

            // Reload data token
            reloadDataToken()(dispatch);

            flow = {
              ...data,
              flowSequence: {
                ...data.flowSequence,
                newsLetterWelcome: {
                  ...data.flowSequence.newsLetterWelcome,
                  image: null,
                },
              },
            };
          } else {
            const image = welcomeData.file;

            // Save image in the storage
            const metaData = { contentType: image.type };
            const res = await storage
              .ref()
              .child(
                uid + "/" + data.idVenue + "/" + data.idQrFlow + "/sign up/"
              )
              .child("logo.png")
              .put(image, metaData);
            const imageURL = await res.ref.getDownloadURL();

            // Update data base
            await db
              .collection("users")
              .doc(uid)
              .collection("venues")
              .doc(data.idVenue)
              .collection("qrFlows")
              .doc(data.idQrFlow)
              .update({
                "flowSequence.newsLetterWelcome": {
                  ...data.flowSequence.newsLetterWelcome,
                  image: imageURL,
                },
              });

            // Reload data token
            reloadDataToken()(dispatch);

            flow = {
              ...data,
              flowSequence: {
                ...data.flowSequence,
                newsLetterWelcome: {
                  ...data.flowSequence.newsLetterWelcome,
                  image: imageURL,
                },
              },
            };
          }
        } else {
          // Update data base
          await db
            .collection("users")
            .doc(uid)
            .collection("venues")
            .doc(data.idVenue)
            .collection("qrFlows")
            .doc(data.idQrFlow)
            .update({
              "flowSequence.newsLetterWelcome": {
                ...data.flowSequence.newsLetterWelcome,
                ...welcomeData,
              },
            });

          // Reload data token
          reloadDataToken()(dispatch);

          flow = {
            ...data,
            flowSequence: {
              ...data.flowSequence,
              newsLetterWelcome: {
                ...data.flowSequence.newsLetterWelcome,
                ...welcomeData,
              },
            },
          };
        }

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: UPDATE_WELCOME_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L725 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_WELCOME_QR_FLOW_ERROR,
        });
      }
    }
  };

export const updateSignUpQrFlow =
  ({ data = {}, signUpData = {} }) =>
  async (dispatch, getState) => {
    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Update data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "flowSequence.newsLetterSignUp": {
              ...data.flowSequence.newsLetterSignUp,
              ...signUpData,
            },
          });

        // Reload data token
        reloadDataToken()(dispatch);

        const flow = {
          ...data,
          flowSequence: {
            ...data.flowSequence,
            newsLetterSignUp: {
              ...data.flowSequence.newsLetterSignUp,
              ...signUpData,
            },
          },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: UPDATE_SIGN_UP_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L794 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_SIGN_UP_QR_FLOW_ERROR,
        });
      }
    }
  };

/* QR */

export const updateQrSelected =
  ({ data = {}, selectedOption = "noLogo" }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_QR_FLOW_QR,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Update data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "qrCode.selectedOption": selectedOption,
          });

        // Reload data token
        reloadDataToken()(dispatch);

        const flow = {
          ...data,
          qrCode: { ...data.qrCode, selectedOption: selectedOption },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: UPDATE_QR_SELECTED_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L861 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_QR_SELECTED_ERROR,
        });
      }
    }
  };

export const updateQrCustomLogo =
  ({ data = {}, file = null, logo = null, config = {}, style = "normal" }) =>
  async (dispatch, getState) => {
    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      // Get prices type from the user
      const { pricesType } = getState().user.data;
      let prices = 6;
      // Validate that the prices type exist
      if (pricesType) {
        prices = pricesType;
      }

      try {
        const configQR = { ...config };
        // Validate style
        if (style === "inverted") {
          const bodyColor = configQR.bodyColor;
          const bgColor = configQR.bgColor;

          configQR.bgColor = bodyColor;
          configQR.bodyColor = bgColor;
          configQR.eye1Color = bgColor;
          configQR.eye2Color = bgColor;
          configQR.eye3Color = bgColor;
          configQR.eyeBall1Color = bgColor;
          configQR.eyeBall2Color = bgColor;
          configQR.eyeBall3Color = bgColor;
        }

        const qrCustomLogo = await CreateQrCodeWithLogoFast({
          url: `${window.origin}/#/scan/${uid}/${data.idVenue}/${data.idQrFlow}`,
          logo: file,
          pricesType: prices,
          userId: uid,
          venueId: data.idVenue,
          qrFlowId: data.idQrFlow,
          config: configQR,
        });

        const flow = {
          ...data,
          qrCode: {
            ...data.qrCode,
            config: { ...config },
            style: style,
            logo: logo,
            options: { ...data.qrCode.options, customLogo: qrCustomLogo.image },
            selectedOption: "customLogo",
          },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: CREATE_QR_CUSTOM_LOGO_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );

        // Save image in the storage
        const metaDataQrDefaultLogo = { contentType: qrCustomLogo.file.type };
        const resQrDefaultLogoURL = await storage
          .ref()
          .child(uid + "/" + data.idVenue + "/" + data.idQrFlow + "/QR/")
          .child("customLogo.png")
          .put(qrCustomLogo.file, metaDataQrDefaultLogo);
        const qrCustomLogoURL = await resQrDefaultLogoURL.ref.getDownloadURL();

        // Save logo in the storage
        const metaDataLogo = { contentType: file.type };
        const resLogoURL = await storage
          .ref()
          .child(uid + "/" + data.idVenue + "/" + data.idQrFlow + "/QR/")
          .child("logo.png")
          .put(file, metaDataLogo);
        const logoURL = await resLogoURL.ref.getDownloadURL();

        // Update data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "qrCode.config": { ...config },
            "qrCode.style": style,
            "qrCode.logo": logoURL,
            "qrCode.options.customLogo": qrCustomLogoURL,
            "qrCode.selectedOption": "customLogo",
          });

        // Reload data token
        reloadDataToken()(dispatch);
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L985 @ qrFlowDucks.js | ${error.message}`,
        });

        dispatch({
          type: CREATE_QR_CUSTOM_LOGO_ERROR,
        });
      }
    }
  };

export const updateQrNoLogo =
  ({ data = {}, config = {}, style = "normal" }) =>
  async (dispatch, getState) => {
    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      // Get prices type from the user
      const { pricesType } = getState().user.data;
      let prices = 6;
      // Validate that the prices type exist
      if (pricesType) {
        prices = pricesType;
      }

      try {
        const configQR = { ...config };
        // Validate style
        if (style === "inverted") {
          const bodyColor = configQR.bodyColor;
          const bgColor = configQR.bgColor;

          configQR.bgColor = bodyColor;
          configQR.bodyColor = bgColor;
          configQR.eye1Color = bgColor;
          configQR.eye2Color = bgColor;
          configQR.eye3Color = bgColor;
          configQR.eyeBall1Color = bgColor;
          configQR.eyeBall2Color = bgColor;
          configQR.eyeBall3Color = bgColor;
        }

        const qrNoLogo = await CreateQRCodeFast({
          url: `${window.origin}/#/scan/${uid}/${data.idVenue}/${data.idQrFlow}`,
          pricesType: prices,
          userId: uid,
          venueId: data.idVenue,
          qrFlowId: data.idQrFlow,
          config: configQR,
        });

        const flow = {
          ...data,
          qrCode: {
            ...data.qrCode,
            config: { ...config },
            style: style,
            logo: null,
            options: { ...data.qrCode.options, noLogo: qrNoLogo.image },
            selectedOption: "noLogo",
          },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: CREATE_QR_CUSTOM_LOGO_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );

        // Save image in the storage
        const metaDataQrDefaultLogo = { contentType: qrNoLogo.file.type };
        const resQrDefaultLogoURL = await storage
          .ref()
          .child(uid + "/" + data.idVenue + "/" + data.idQrFlow + "/QR/")
          .child("noLogo.png")
          .put(qrNoLogo.file, metaDataQrDefaultLogo);
        const qrNoLogoURL = await resQrDefaultLogoURL.ref.getDownloadURL();

        // Update data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "qrCode.config": { ...config },
            "qrCode.style": style,
            "qrCode.logo": null,
            "qrCode.options.noLogo": qrNoLogoURL,
            "qrCode.selectedOption": "noLogo",
          });

        // Reload data token
        reloadDataToken()(dispatch);
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L1098 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: CREATE_QR_CUSTOM_LOGO_ERROR,
        });
      }
    }
  };

export const deleteQrCustomLogo =
  ({ data = {} }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_QR_FLOW_QR,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Update data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            "qrCode.options.customLogo": null,
            "qrCode.selectedOption": "defaultLogo",
          });

        // Reload data token
        reloadDataToken()(dispatch);

        const flow = {
          ...data,
          qrCode: {
            ...data.qrCode,
            options: { ...data.qrCode.options, customLogo: null },
            selectedOption: "defaultLogo",
          },
        };

        // Update the flow with the current flow
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
        );

        dispatch({
          type: DELETE_QR_CUSTOM_LOGO_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L1168 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: DELETE_QR_CUSTOM_LOGO_ERROR,
        });
      }
    }
  };

export const starQrLoading = () => async (dispatch) => {
  dispatch({
    type: START_LOADING_QR_FLOW,
  });
};

export const endQrLoading = () => async (dispatch) => {
  dispatch({
    type: END_LOADING_QR_FLOW,
  });
};

export const createQrFlow =
  ({ idVenue = "", name = "", index }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_QR_FLOW,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;
      let idFlow = "";

      // Get prices type from the user
      const { pricesType } = getState().user.data;
      let prices = 6;
      // Validate that the prices type exist
      if (pricesType) {
        prices = pricesType;
      }

      try {
        // Flow structure
        const newFlow = {
          name: name,
          qrCode: {
            config: {
              body: "dot",
              eye: "frame1",
              eyeBall: "ball1",
              erf1: ["fh"],
              brf1: ["fh"],
              erf3: ["fv", "fh"],
              brf3: ["fv", "fh"],
              bodyColor: "#000",
              bgColor: "#fff",
              eye1Color: "#000",
              eye2Color: "#000",
              eye3Color: "#000",
              eyeBall1Color: "#000",
              eyeBall2Color: "#000",
              eyeBall3Color: "#000",
            },
            style: "normal",
            logo: null,
            options: {
              noLogo: null,
              defaultLogo: null,
              customLogo: null,
            },
            selectedOption: "defaultLogo",
          },
          flowSequence: {
            newsLetterSignUp: {
              active: false,
              text: "Subscribe to our newsletter",
              firstName: false,
              lastName: false,
              emailAddress: true,
              phoneNumber: false,
              birthday: false,
              image: null,
              answerYes: "Subscribe",
              answerNo: "No, thanks",
            },
            showMenu: [],
          },
          sequence: ["newsLetterSignUp", "showMenu"],
          customUrl: "",
        };

        const qrFlow = await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(idVenue)
          .collection("qrFlows")
          .add({
            ...newFlow,
          });

        // Save id
        idFlow = qrFlow.id;

        // Create customs URL
        const customUrl = await CreateCustomUrl({
          userId: uid,
          venueId: idVenue,
          qrFlowId: idFlow,
        });

        // Update in DataBase
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(idVenue)
          .collection("qrFlows")
          .doc(idFlow)
          .update({
            customUrl: customUrl,
          });

        // Reload data token
        reloadDataToken()(dispatch);

        // Create QR codes
        createQRs({
          uid: uid,
          idVenue: idVenue,
          idQrFlow: idFlow,
          pricesType: prices,
        })(dispatch);

        const flow = [
          ...flows,
          {
            ...newFlow,
          },
        ];

        dispatch({
          type: CREATE_QR_FLOW_EXIT,
          payload: { flow: flow, index: index },
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...flow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L1328 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        // Delete structure
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(idVenue)
          .collection("qrFlows")
          .doc(idFlow)
          .delete();

        dispatch({
          type: CREATE_QR_FLOW_ERROR,
        });
      }
    }
  };

export const deleteQrFlow =
  ({ data = {} }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_QR_FLOW,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Delete from data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .delete();

        // Delete last custom url from in DataBase
        await db.collection("customUrls").doc(data.customUrl).delete();

        // Delete from storage
        const dataApi = {
          userId: uid,
          venueId: data.idVenue,
          qrFlowId: data.idQrFlow,
        };

        const deleteFolderStorage = functions.httpsCallable(
          "deleteFolderStorage"
        );
        deleteFolderStorage(dataApi);

        // Delete from redux
        const newFlow = flows.filter(
          (item, index) => item.idQrFlow !== data.idQrFlow
        );

        dispatch({
          type: DELETE_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save error
        openToast({
          content: <p> {i18n.t("toastSuccessMenuDeleted")} </p>,
          type: SUCCESS,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L1412 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: DELETE_QR_FLOW_ERROR,
        });
      }
    }
  };

export const updateCustomUrl =
  ({ data = {}, customUrl = "" }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_QR_FLOW,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Create customs URL
        const customUrlRes = await CreateCustomUrl({
          userId: uid,
          venueId: data.idVenue,
          qrFlowId: data.idQrFlow,
          customUrl: customUrl,
        });

        if (customUrlRes === "Exit") {
          // Update in DataBase
          await db
            .collection("users")
            .doc(uid)
            .collection("venues")
            .doc(data.idVenue)
            .collection("qrFlows")
            .doc(data.idQrFlow)
            .update({
              customUrl: customUrl,
            });

          // Reload data token
          reloadDataToken()(dispatch);

          // Delete last custom url from in DataBase
          await db.collection("customUrls").doc(data.customUrl).delete();

          const flow = { ...data, customUrl: customUrl };

          // Update the flow with the current flow
          const newFlow = flows.map((item) =>
            item.idQrFlow === data.idQrFlow ? { ...flow } : { ...item }
          );

          analytics.logEvent("create_custom_url", {
            new_user_id: uid,
            venue_id: data.idVenue,
            qr_flow_id: data.idQrFlow,
            custom_url: customUrl,
            description: `${customUrl} - ${uid} - ${data.idVenue} - ${data.idQrFlow}`,
          });

          dispatch({
            type: UPDATE_CUSTOM_URL_EXIT,
            payload: newFlow,
          });

          // Save in the browser
          localStorage.setItem(
            "qrFlow",
            JSON.stringify({
              data: [...newFlow],
            })
          );
        } else {
          openToast({
            content: <p> {i18n.t("toastErrorCustomUrlNotAvailable")} </p>,
            type: ERROR,
          });

          dispatch({
            type: UPDATE_CUSTOM_URL_ERROR,
          });
        }
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L1508 @ qrFlowDucks.js | ${error.code} - ${error.message} - uid:${uid}`,
        });

        dispatch({
          type: UPDATE_CUSTOM_URL_ERROR,
        });
      }
    }
  };

export const updateQrFlowName =
  ({ data = {}, name = "New Menu", index = -1 }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_QR_FLOW,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const { flows } = getState().qrFlow;

      try {
        // Combine the name with the number of menu
        let newMenuName = `Menu ${index + 1} - ${name}`;

        // Delete from data base
        await db
          .collection("users")
          .doc(uid)
          .collection("venues")
          .doc(data.idVenue)
          .collection("qrFlows")
          .doc(data.idQrFlow)
          .update({
            name: newMenuName,
          });

        // Reload data token
        reloadDataToken()(dispatch);

        // Delete from redux
        const newFlow = flows.map((item) =>
          item.idQrFlow === data.idQrFlow
            ? { ...data, name: newMenuName }
            : item
        );

        dispatch({
          type: UPDATE_QR_FLOW_EXIT,
          payload: newFlow,
        });

        // Save in the browser
        localStorage.setItem(
          "qrFlow",
          JSON.stringify({
            data: [...newFlow],
          })
        );
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L1571 @ qrFlowDucks.js | ${error.code} - ${error.message} - uid:${uid}`,
        });

        dispatch({
          type: UPDATE_QR_FLOW_ERROR,
        });
      }
    }
  };

export const createQRs =
  ({ uid = "", idVenue = "", idQrFlow = "", pricesType = 6 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: START_LOADING_QR_FLOW,
    });

    try {
      // Create QR codes
      const qrNoLogo = await CreateQRCodeFast({
        url: `${window.origin}/#/scan/${uid}/${idVenue}/${idQrFlow}`,
        pricesType: pricesType,
        userId: uid,
        venueId: idVenue,
        qrFlowId: idQrFlow,
      });
      // Save image in the storage
      const metaDataQrNoLogo = { contentType: qrNoLogo.file.type };
      const resQrNoLogo = await storage
        .ref()
        .child(uid + "/" + idVenue + "/" + idQrFlow + "/QR/")
        .child("noLogo.png")
        .put(qrNoLogo.file, metaDataQrNoLogo);
      const qrNoLogoURL = await resQrNoLogo.ref.getDownloadURL();

      const qrDefaultLogo = await CreateQrCodeWithLogoFast({
        url: `${window.origin}/#/scan/${uid}/${idVenue}/${idQrFlow}`,
        pricesType: pricesType,
        userId: uid,
        venueId: idVenue,
        qrFlowId: idQrFlow,
      });
      // Save image in the storage
      const metaDataQrDefaultLogo = { contentType: qrDefaultLogo.file.type };
      const resQrDefaultLogoURL = await storage
        .ref()
        .child(uid + "/" + idVenue + "/" + idQrFlow + "/QR/")
        .child("defaultLogo.png")
        .put(qrDefaultLogo.file, metaDataQrDefaultLogo);
      const qrDefaultLogoURL = await resQrDefaultLogoURL.ref.getDownloadURL();

      // Update data base
      await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .doc(idVenue)
        .collection("qrFlows")
        .doc(idQrFlow)
        .update({
          "qrCode.options.noLogo": qrNoLogoURL,
          "qrCode.options.defaultLogo": qrDefaultLogoURL,
        });

      // Reload data token
      reloadDataToken()(dispatch);

      // Update redux state
      getQrFlowsData({ readDB: true })(dispatch);

      dispatch({
        type: END_LOADING_QR_FLOW,
      });
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L1648 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
      });

      // Delete structure
      await db
        .collection("users")
        .doc(uid)
        .collection("venues")
        .doc(idVenue)
        .collection("qrFlows")
        .doc(idQrFlow)
        .delete();

      dispatch({
        type: END_LOADING_QR_FLOW,
      });
    }
  };

/* Reset qr flow */

export const restartDataQrFlow = () => async (dispatch) => {
  dispatch({
    type: RESTART_QR_FLOW,
  });
};
