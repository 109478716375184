/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import { auth, firebase, analytics, db } from "../controller/firebase";

import { createUserStructure, reloadDataToken } from "./userDucks";

import { restartDataCustomers } from "./customersDucks";
import { restartDataQrFlow } from "./qrFlowDucks";
import { restartDataStripe } from "./stripeDucks";
import { restartDataToast } from "./toastDucks";
import { restartDataUser, updateDataUser } from "./userDucks";
import { restartDataVenue } from "./venueDucks";

import { openToast, ERROR, SUCCESS } from "../constant/Toast";

import M from "materialize-css";

import i18n from "i18next";
import { ListFirebaseCustomError } from "../helpers/ListFirebaseCustomErrors";

/* Constants or States */
const initData = {
  loading: false,
  active: false,
};

const LOADING_AUTH = "LOADING_AUTH";

const AUTH_EXIT = "AUTH_EXIT";
const AUTH_ERROR = "AUTH_ERROR";

const UPDATE_PASSWORD_EXIT = "UPDATE_PASSWORD_EXIT";
const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

const REST_PASSWORD_EXIT = "REST_PASSWORD_EXIT";
const REST_PASSWORD_ERROR = "REST_PASSWORD_ERROR";

const UPDATE_EMAIL_EXIT = "UPDATE_EMAIL_EXIT";
const UPDATE_EMAIL_ERROR = "UPDATE_EMAIL_ERROR";

const LOGOUT = "LOGOUT";

/* Reducer (Save call API in constant) */
export default function authReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_AUTH:
      return { ...state, loading: true };
    case AUTH_EXIT:
      return { ...state, loading: false, active: true, ...action.payload };
    case AUTH_ERROR:
      return { ...initData };
    case LOGOUT:
      return { ...initData };
    case UPDATE_PASSWORD_EXIT:
      return { ...state, loading: false };
    case UPDATE_PASSWORD_ERROR:
      return { ...state, loading: false };
    case REST_PASSWORD_EXIT:
      return { ...state, loading: false };
    case REST_PASSWORD_ERROR:
      return { ...state, loading: false };
    case UPDATE_EMAIL_EXIT:
      return { ...state, ...action.payload, loading: false };
    case UPDATE_EMAIL_ERROR:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
}

/* Actions (Calls API) */
export const loginUserWithGoogle =
  ({ from = "home", country = "US" }) =>
  async (dispatch, getState) => {
    // Init loading to register or login user
    dispatch({
      type: LOADING_AUTH,
    });

    try {
      // Create new user
      const provider = new firebase.auth.GoogleAuthProvider();
      const res = await auth.signInWithPopup(provider);

      // Detect new if user is new to create structure
      if (res.additionalUserInfo.isNewUser) {
        await createUserStructure({
          uid: res.user.uid,
          email: res.user.email,
          country: country,
          from: from,
        })(dispatch);
        analytics.logEvent("sign_up", {
          method: "google",
          resolution: `${window.screen.width} x ${window.screen.height}`,
          prices_type: 6,
          new_user_id: res.user.uid,
          email: res.user.email,
          from: from,
        });
      }

      let providers = [];

      for (let i = 0; i < res.user.providerData.length; i++) {
        providers = [...providers, res.user.providerData[i].providerId];
      }

      analytics.logEvent("log_in", {
        method: "google",
        new_user_id: res.user.uid,
        email: res.user.email,
      });

      dispatch({
        type: AUTH_EXIT,
        payload: {
          uid: res.user.uid,
          email: res.user.email,
          provider: providers,
        },
      });

      // Save in the browser
      localStorage.setItem(
        "auth",
        JSON.stringify({
          uid: res.user.uid,
          email: res.user.email,
          provider: providers,
        })
      );
    } catch (error) {
      const errorCode = error.code.split("/");
      const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

      // Save error
      openToast({
        content: <p> {errorMessage} </p>,
        type: ERROR,
      });

      if (errorMessage === "An unexpected error occurred, please try again") {
        analytics.logEvent("new_error", {
          description: `L126 @ authDucks.js | ${error.code} - ${error.message}`,
        });
      } else {
        analytics.logEvent("new_error", {
          description: `L126 @ authDucks.js | ${errorMessage}`,
        });
      }

      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const singUpWithEmailPassword =
  ({ email, pass, from, country = "US" }) =>
  async (dispatch) => {
    // Init loading to register or login user
    dispatch({
      type: LOADING_AUTH,
    });

    try {
      // Create new user
      const res = await auth.createUserWithEmailAndPassword(email, pass);

      // Save user structure
      createUserStructure({
        uid: res.user.uid,
        email: res.user.email,
        country: country,
        from: from,
      })(dispatch);

      let providers = [];

      for (let i = 0; i < res.user.providerData.length; i++) {
        providers = [...providers, res.user.providerData[i].providerId];
      }

      analytics.logEvent("sign_up", {
        method: "email",
        resolution: `${window.screen.width} x ${window.screen.height}`,
        prices_type: 6,
        new_user_id: res.user.uid,
        email: res.user.email,
        from: from,
      });

      dispatch({
        type: AUTH_EXIT,
        payload: {
          uid: res.user.uid,
          email: res.user.email,
          provider: providers,
        },
      });

      // Save in the browser
      localStorage.setItem(
        "auth",
        JSON.stringify({
          uid: res.user.uid,
          email: res.user.email,
          provider: providers,
        })
      );
    } catch (error) {
      const errorCode = error.code.split("/");
      const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

      // Save error
      openToast({
        content: <p> {errorMessage} </p>,
        type: ERROR,
        time: 6000,
      });

      if (errorMessage === "An unexpected error occurred, please try again") {
        analytics.logEvent("new_error", {
          description: `L180 @ authDucks.js | ${error.code} - ${error.message}`,
        });
      } else {
        analytics.logEvent("new_error", {
          description: `L180 @ authDucks.js | ${errorMessage}`,
        });
      }

      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const loginWithEmailPassword = (email, pass) => async (dispatch) => {
  // Init loading to register or login user
  dispatch({
    type: LOADING_AUTH,
  });

  try {
    const res = await auth.signInWithEmailAndPassword(email, pass);

    let providers = [];

    for (let i = 0; i < res.user.providerData.length; i++) {
      providers = [...providers, res.user.providerData[i].providerId];
    }

    reloadDataToken(false)(dispatch);

    analytics.logEvent("log_in", {
      method: "email",
      new_user_id: res.user.uid,
      email: res.user.email,
    });

    dispatch({
      type: AUTH_EXIT,
      payload: {
        uid: res.user.uid,
        email: res.user.email,
        provider: providers,
      },
    });

    // Save in the browser
    localStorage.setItem(
      "auth",
      JSON.stringify({
        uid: res.user.uid,
        email: res.user.email,
        provider: providers,
      })
    );
  } catch (error) {
    const errorCode = error.code.split("/");
    const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

    // Save error
    openToast({
      content: <p> {errorMessage} </p>,
      type: ERROR,
      time: 6000,
    });

    if (errorMessage === "An unexpected error occurred, please try again") {
      analytics.logEvent("new_error", {
        description: `L228 @ authDucks.js | ${error.code} - ${error.message}`,
      });
    } else {
      analytics.logEvent("new_error", {
        description: `L228 @ authDucks.js | ${errorMessage}`,
      });
    }

    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActiveAuth = () => async (dispatch) => {
  if (localStorage.getItem("auth")) {
    dispatch({
      type: AUTH_EXIT,
      payload: JSON.parse(localStorage.getItem("auth")),
    });
  }
};

export const updatePassword =
  ({ data = {} }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_AUTH,
    });

    if (localStorage.getItem("auth")) {
      try {
        const user = auth.currentUser;
        const email = JSON.parse(localStorage.getItem("auth")).email;
        const pass = data.currentPassword;

        const provider = JSON.parse(localStorage.getItem("auth")).provider;

        const existPassword = provider.includes("password");

        // Validate if the password it's equals
        if (data.newPassword === data.confirmNewPassword) {
          if (!existPassword) {
            // Link with email and pass provider
            try {
              // Sign in user
              const provider = new firebase.auth.GoogleAuthProvider();
              await auth.signInWithPopup(provider);

              // Link google provider with password provider
              await user.linkWithCredential(
                firebase.auth.EmailAuthProvider.credential(
                  email,
                  data.newPassword
                )
              );

              const updateUser = auth.currentUser;

              // Update providers
              let providers = [];

              for (let i = 0; i < updateUser.providerData.length; i++) {
                providers = [
                  ...providers,
                  updateUser.providerData[i].providerId,
                ];
              }

              dispatch({
                type: AUTH_EXIT,
                payload: {
                  uid: updateUser.uid,
                  email: updateUser.email,
                  provider: providers,
                },
              });

              openToast({
                content: <p> {i18n.t("toastSuccessPassUpdated")} </p>,
                type: SUCCESS,
              });

              analytics.logEvent("toast", {
                description: `L296 @ authDucks.jsx | Your new password was updated`,
              });

              // Save in the browser
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  uid: updateUser.uid,
                  email: updateUser.email,
                  provider: providers,
                })
              );
            } catch (error) {
              // Show toast unexpected error reload
              document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide");
              // Save analytics
              analytics.logEvent("new_error", {
                description: `L309 @ authDucks.js | ${error.code} - ${error.message}`,
              });

              dispatch({
                type: UPDATE_PASSWORD_ERROR,
              });
            }
          } else {
            // Sign in user
            await auth.signInWithEmailAndPassword(email, pass);

            if (data.newPassword === data.confirmNewPassword) {
              const newPassword = data.newPassword;

              await user.updatePassword(newPassword).then(function () {
                dispatch({
                  type: UPDATE_PASSWORD_EXIT,
                });
              });

              openToast({
                content: <p> {i18n.t("toastSuccessPassUpdated")} </p>,
                type: SUCCESS,
              });

              analytics.logEvent("toast", {
                description: `L332 @ authDucks.jsx | Your new password was updated`,
              });
            }
          }
        } else {
          openToast({
            content: <p> {i18n.t("toastErrorPassNotMatch")} </p>,
            type: ERROR,
          });

          analytics.logEvent("toast", {
            description: `L341 @ authDucks.jsx | Your new password doesn’t match the password confirmation`,
          });

          dispatch({
            type: UPDATE_PASSWORD_ERROR,
          });
        }
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L351 @ authDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_PASSWORD_ERROR,
        });
      }
    }
  };

export const resetPassword =
  ({ email = "" }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_AUTH,
    });

    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        openToast({
          content: <p> {i18n.t("toastSuccessLinkPassSent")} </p>,
          type: SUCCESS,
        });

        analytics.logEvent("toast", {
          description: `L371 @ authDucks.jsx | A link to reset your password was sent to your email`,
        });

        analytics.logEvent("forgot_password", {
          email: email,
        });

        dispatch({
          type: REST_PASSWORD_EXIT,
        });
      })
      .catch(function (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L384 @ authDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: REST_PASSWORD_ERROR,
        });
      });
  };

export const updateEmail =
  ({ newEmail = "", password = null, isDownload = false }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_AUTH,
    });

    if (localStorage.getItem("auth")) {
      // Get user's current email
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const providers = JSON.parse(localStorage.getItem("auth")).provider;
      const currentEmail = JSON.parse(localStorage.getItem("auth")).email;

      if (currentEmail === null) {
        try {
          if (!password) {
            // open modal
            dispatch({
              type: UPDATE_EMAIL_EXIT,
              payload: {
                newEmail: newEmail,
              },
            });

            const elem = document.getElementById("modal-password");
            const instance = M.Modal.getInstance(elem);
            instance.open();
          } else {
            const email = newEmail ? newEmail : getState().auth.newEmail;
            const credential = firebase.auth.EmailAuthProvider.credential(
              email,
              password
            );

            await auth.currentUser.linkWithCredential(credential);

            openToast({
              content: <p> {i18n.t("toastSuccessEmailUpdated")} </p>,
              type: SUCCESS,
            });

            analytics.logEvent("toast", {
              description: `L428 @ authDucks.jsx | Your email was updated`,
            });

            // open modal to download QR code
            if (isDownload) {
              const elem = document.getElementById("modal-actions-qr");
              const instance = M.Modal.getInstance(elem);
              instance.open();
            }

            // Update email reminder
            const emailReminders = await db
              .collection("emailReminder")
              .where("idUser", "==", uid)
              .get();
            for (let i in emailReminders.docs) {
              const emailReminder = emailReminders.docs[i];
              await db
                .collection("emailReminder")
                .doc(emailReminder.id)
                .update({
                  email: email,
                  dateCreated: new Date(),
                });
            }

            // Update feedback user
            const feedbackUsers = await db
              .collection("feedbackUsers")
              .where("userId", "==", uid)
              .get();
            for (let i in feedbackUsers.docs) {
              const feedbackUser = feedbackUsers.docs[i];
              await db.collection("feedbackUsers").doc(feedbackUser.id).update({
                emailSuperMenu: email,
              });
            }

            // Data from user
            const { firstName, lastName, phone } = getState().user.data;

            // Update user redux
            await updateDataUser({
              data: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
              },
              newEmail: email,
            })(dispatch);

            // Save in the browser
            localStorage.setItem(
              "auth",
              JSON.stringify({
                uid: uid,
                email: email,
                provider: ["password"],
              })
            );

            // Update auth redux
            dispatch({
              type: UPDATE_EMAIL_EXIT,
              payload: {
                email: email,
              },
            });
          }
          return;
        } catch (error) {
          const errorCode = error.code.split("/");
          const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

          // Save error
          openToast({
            content: <p> {errorMessage} </p>,
            type: ERROR,
            time: 6000,
          });

          if (
            errorMessage === "An unexpected error occurred, please try again"
          ) {
            analytics.logEvent("new_error", {
              description: `L482 @ authDucks.js | ${error.code} - ${error.message}`,
            });
          } else {
            analytics.logEvent("new_error", {
              description: `L482 @ authDucks.js | ${errorMessage}`,
            });
          }

          dispatch({
            type: UPDATE_EMAIL_ERROR,
          });
          return;
        }
      }

      if (newEmail !== currentEmail) {
        const user = auth.currentUser;

        try {
          // Get type providers
          const provider = JSON.parse(localStorage.getItem("auth")).provider;
          const existPassword = provider.includes("password");

          // Data from user
          const { firstName, lastName, phone } = getState().user.data;
          if (!existPassword) {
            if (!password) {
              // Sign in user
              const provider = new firebase.auth.GoogleAuthProvider();
              await auth.signInWithPopup(provider);

              // open modal
              dispatch({
                type: UPDATE_EMAIL_EXIT,
                payload: {
                  newEmail: newEmail,
                },
              });

              const elem = document.getElementById("modal-password");
              const instance = M.Modal.getInstance(elem);
              instance.open();
            } else {
              const email = newEmail ? newEmail : getState().auth.newEmail;

              const credential = firebase.auth.EmailAuthProvider.credential(
                email,
                password
              );

              await auth.currentUser.linkWithCredential(credential);

              openToast({
                content: <p> {i18n.t("toastSuccessEmailUpdated")} </p>,
                type: SUCCESS,
              });

              analytics.logEvent("toast", {
                description: `L511 @ authDucks.jsx | Your email was updated`,
              });

              // Update email reminder
              const emailReminders = await db
                .collection("emailReminder")
                .where("idUser", "==", uid)
                .get();
              for (let i in emailReminders.docs) {
                const emailReminder = emailReminders.docs[i];
                await db
                  .collection("emailReminder")
                  .doc(emailReminder.id)
                  .update({
                    email: email,
                    dateCreated: new Date(),
                  });
              }

              // Update feedback user
              const feedbackUsers = await db
                .collection("feedbackUsers")
                .where("userId", "==", uid)
                .get();
              for (let i in feedbackUsers.docs) {
                const feedbackUser = feedbackUsers.docs[i];
                await db
                  .collection("feedbackUsers")
                  .doc(feedbackUser.id)
                  .update({
                    emailSuperMenu: email,
                  });
              }

              // Update user redux
              await updateDataUser({
                data: {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  phone: phone,
                },
                newEmail: email,
              })(dispatch);

              // Save in the browser
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  uid: uid,
                  email: email,
                  provider: ["google.com", "password"],
                })
              );

              // Update auth redux
              dispatch({
                type: UPDATE_EMAIL_EXIT,
                payload: {
                  email: email,
                },
              });
            }
          } else {
            if (password) {
              // Sign in user
              await auth.signInWithEmailAndPassword(currentEmail, password);
              const { newEmail } = getState().auth;

              user
                .updateEmail(newEmail)
                .then(async function () {
                  openToast({
                    content: <p> {i18n.t("toastSuccessEmailUpdated")} </p>,
                    type: SUCCESS,
                  });

                  analytics.logEvent("toast", {
                    description: `L559 @ authDucks.jsx | Your email was updated`,
                  });

                  // Update email reminder
                  const emailReminders = await db
                    .collection("emailReminder")
                    .where("idUser", "==", uid)
                    .get();
                  for (let i in emailReminders.docs) {
                    const emailReminder = emailReminders.docs[i];
                    await db
                      .collection("emailReminder")
                      .doc(emailReminder.id)
                      .update({
                        email: newEmail,
                        dateCreated: new Date(),
                      });
                  }

                  // Update feedback user
                  const feedbackUsers = await db
                    .collection("feedbackUsers")
                    .where("userId", "==", uid)
                    .get();
                  for (let i in feedbackUsers.docs) {
                    const feedbackUser = feedbackUsers.docs[i];
                    await db
                      .collection("feedbackUsers")
                      .doc(feedbackUser.id)
                      .update({
                        emailSuperMenu: newEmail,
                      });
                  }

                  // Update user redux
                  await updateDataUser({
                    data: {
                      firstName: firstName,
                      lastName: lastName,
                      email: newEmail,
                      phone: phone,
                    },
                    newEmail: newEmail,
                  })(dispatch);

                  // Save in the browser
                  localStorage.setItem(
                    "auth",
                    JSON.stringify({
                      uid: uid,
                      email: newEmail,
                      provider: [...providers],
                    })
                  );

                  // Update auth redux
                  dispatch({
                    type: UPDATE_EMAIL_EXIT,
                    payload: {
                      email: newEmail,
                    },
                  });
                })
                .catch(function (error) {
                  const errorCode = error.code.split("/");
                  const errorMessage = ListFirebaseCustomError({
                    code: errorCode[1],
                  });

                  // Save error
                  openToast({
                    content: <p> {errorMessage} </p>,
                    type: ERROR,
                    time: 6000,
                  });

                  if (
                    errorMessage ===
                    "An unexpected error occurred, please try again"
                  ) {
                    analytics.logEvent("new_error", {
                      description: `L591 @ authDucks.js | ${error.code} - ${error.message}`,
                    });
                  } else {
                    analytics.logEvent("new_error", {
                      description: `L591 @ authDucks.js | ${errorMessage}`,
                    });
                  }

                  dispatch({
                    type: UPDATE_EMAIL_ERROR,
                  });
                });
            } else {
              // open modal
              dispatch({
                type: UPDATE_EMAIL_EXIT,
                payload: {
                  newEmail: newEmail,
                },
              });

              const elem = document.getElementById("modal-password");
              const instance = M.Modal.getInstance(elem);
              instance.open();
            }
          }
        } catch (error) {
          const errorCode = error.code.split("/");
          const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

          // Save error
          openToast({
            content: <p> {errorMessage} </p>,
            type: ERROR,
            time: 6000,
          });

          if (
            errorMessage === "An unexpected error occurred, please try again"
          ) {
            analytics.logEvent("new_error", {
              description: `L616 @ authDucks.js | ${error.code} - ${error.message}`,
            });
          } else {
            analytics.logEvent("new_error", {
              description: `L616 @ authDucks.js | ${errorMessage}`,
            });
          }

          dispatch({
            type: UPDATE_EMAIL_ERROR,
          });
        }
      }
    }
  };

export const logout = () => (dispatch) => {
  auth.signOut();
  // Delete all info of the browser
  localStorage.clear();
  dispatch({
    type: LOGOUT,
  });

  // Restart store
  restartDataCustomers()(dispatch);
  restartDataQrFlow()(dispatch);
  restartDataStripe()(dispatch);
  restartDataToast()(dispatch);
  restartDataUser()(dispatch);
  restartDataVenue()(dispatch);
  //window.location.reload();
};

export const reload = () => () => {
  if (localStorage.getItem("auth")) {
    const uid = JSON.parse(localStorage.getItem("auth")).uid;

    try {
      db.collection("users")
        .doc(uid)
        .onSnapshot(async (snapshot) => {
          if (snapshot.data().reloadData) {
            // Set to false
            await db.collection("users").doc(uid).update({
              reloadData: false,
            });
            // Delete all info of the browser
            localStorage.removeItem("venue");
            localStorage.removeItem("customers");
            localStorage.removeItem("toast");
            localStorage.removeItem("user");
            localStorage.removeItem("qrFlow");
            // Reload page
            window.location.reload();
          }

          // Validate if exist the idToken
          if (snapshot.data().idToken && snapshot.data().reloadDataToken) {
            const lastTokenId = snapshot.data().idToken;
            auth.currentUser
              .getIdToken(/* forceRefresh */ false)
              .then(async (currentTokenId) => {
                if (lastTokenId !== currentTokenId) {
                  // Set to false
                  await db.collection("users").doc(uid).update({
                    reloadDataToken: false,
                  });

                  // Delete all info of the browser
                  localStorage.removeItem("venue");
                  localStorage.removeItem("customers");
                  localStorage.removeItem("toast");
                  localStorage.removeItem("user");
                  localStorage.removeItem("qrFlow");
                  // Reload page
                  window.location.reload();
                }
              });
          }
        });
    } catch (error) {}
  }
};

export const singUpAnonymously =
  ({ country = "US" }) =>
  async (dispatch) => {
    // Init loading to register or login user
    dispatch({
      type: LOADING_AUTH,
    });

    try {
      const res = await firebase.auth().signInAnonymously();
      let providers = [];

      for (let i = 0; i < res.user.providerData.length; i++) {
        providers = [...providers, res.user.providerData[i].providerId];
      }

      // Save user structure
      createUserStructure({
        uid: res.user.uid,
        email: res.user.email,
        country: country,
        from: "direct-signup",
      })(dispatch);

      analytics.logEvent("sign_up", {
        method: "anonymous",
        resolution: `${window.screen.width} x ${window.screen.height}`,
        prices_type: 6,
        new_user_id: res.user.uid,
        email: null,
        from: "direct-signup",
      });

      dispatch({
        type: AUTH_EXIT,
        payload: {
          uid: res.user.uid,
          email: res.user.email,
          provider: providers,
        },
      });

      // Save in the browser
      localStorage.setItem(
        "auth",
        JSON.stringify({
          uid: res.user.uid,
          email: res.user.email,
          provider: providers,
        })
      );
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L706 @ authDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
