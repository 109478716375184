import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONF);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

// Active emulator to functions only
// firebase.functions().useEmulator("localhost", 5001);

const db = firebase.firestore();
const functions = firebase.functions();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();

export { db, auth, functions, firebase, storage, analytics };