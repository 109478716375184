/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/
import { db, analytics } from "../controller/firebase";

import { openToast, ERROR } from "../constant/Toast";

import i18n from "i18next";

/* Constants or States */
const initData = {
  loading: false,
  plan: null,
  customer: null,
};

const LOADING_TOAST = "LOADING_TOAST";

const SAVE_TOAST_PLAN_EXIT = "SAVE_TOAST_PLAN_EXIT";
const SAVE_TOAST_PLAN_ERROR = "SAVE_TOAST_PLAN_ERROR";

const SAVE_CUSTOMER_PLAN_EXIT = "SAVE_CUSTOMER_PLAN_EXIT";
const SAVE_CUSTOMER_PLAN_ERROR = "SAVE_CUSTOMER_PLAN_ERROR";

const GET_TOAST_EXIT = "GET_TOAST_EXIT";
const GET_TOAST_ERROR = "GET_TOAST_ERROR";

const RESTART_TOAST = "RESTART_TOAST";

/* Reducer (Save call API in constant) */
export default function toastReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_TOAST:
      return { ...state, loading: true };
    case SAVE_TOAST_PLAN_EXIT:
      return { ...state, plan: action.payload, loading: false };
    case SAVE_TOAST_PLAN_ERROR:
      return { ...state };
    case SAVE_CUSTOMER_PLAN_EXIT:
      return { ...state, customer: action.payload, loading: false };
    case SAVE_CUSTOMER_PLAN_ERROR:
      return { ...state };
    case GET_TOAST_EXIT:
      return {
        ...state,
        plan: action.payload.plan,
        customer: action.payload.customer,
        loading: false,
      };
    case GET_TOAST_ERROR:
      return { ...initData };
    case RESTART_TOAST:
      return { ...initData };
    default:
      return { ...state };
  }
}

/* Actions (Calls API) */
export const saveToastPlan =
  ({ plan = false }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_TOAST,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      try {
        await db
          .collection("users")
          .doc(uid)
          .set(
            {
              toast: {
                plan: plan,
              },
            },
            { merge: true }
          );
        // Save in the browser
        localStorage.setItem(
          "toast",
          JSON.stringify({
            customer: getState().toast.customer,
            plan: plan,
          })
        );

        dispatch({
          type: SAVE_TOAST_PLAN_EXIT,
          payload: plan,
        });
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L79 @ toastDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: SAVE_TOAST_PLAN_ERROR,
        });
      }
    }
  };

export const saveToastCustomer =
  ({ customer = false }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_TOAST,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;

      try {
        await db
          .collection("users")
          .doc(uid)
          .set(
            {
              toast: {
                customers: customer,
              },
            },
            { merge: true }
          );

        // Save in the browser
        localStorage.setItem(
          "toast",
          JSON.stringify({
            customer: customer,
            plan: getState().toast.plan,
          })
        );

        dispatch({
          type: SAVE_CUSTOMER_PLAN_EXIT,
          payload: customer,
        });
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L116 @ toastDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: SAVE_CUSTOMER_PLAN_ERROR,
        });
      }
    }
  };

export const getDataToast = () => async (dispatch, getState) => {
  // Init loading
  dispatch({
    type: LOADING_TOAST,
  });

  // Validate if the data exist in the browser
  if (localStorage.getItem("toast")) {
    dispatch({
      type: GET_TOAST_EXIT,
      payload: {
        plan: JSON.parse(localStorage.getItem("toast")).plan,
        customer: JSON.parse(localStorage.getItem("toast")).customer,
      },
    });
    return;
  }

  if (localStorage.getItem("auth")) {
    const { toast } = getState().user.data;
    const uid = JSON.parse(localStorage.getItem("auth")).uid;

    try {
      dispatch({
        type: GET_TOAST_EXIT,
        payload: {
          plan: toast.plan,
          customer: toast.customers,
        },
      });

      // Save in the browser
      localStorage.setItem(
        "toast",
        JSON.stringify({
          plan: toast.plan,
          customer: toast.customers,
        })
      );
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L180 @ toastDucks.js | ${error.code} - ${error.message} - ${uid}`,
      });

      dispatch({
        type: GET_TOAST_ERROR,
      });
    }
  }
};

export const saveToastReminder = () => async (dispatch) => {
  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;
    const email = JSON.parse(localStorage.getItem("auth")).email;

    try {
      // Get language
      const lang = navigator.language || navigator.userLanguage;
      // Create reminder
      await db.collection("upgradePlanReminder").doc(uid).set({
        idUser: uid,
        dateCreated: new Date(),
        email: email,
        language: lang,
      });
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L208 @ toastDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: GET_TOAST_ERROR,
      });
    }
  }
};

export const deleteToastReminder = () => async (dispatch) => {
  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;

    try {
      // delete reminder
      await db.collection("upgradePlanReminder").doc(uid).delete();
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L229 @ toastDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: GET_TOAST_ERROR,
      });
    }
  }
};

// Reset toast
export const restartDataToast = () => async (dispatch) => {
  dispatch({
    type: RESTART_TOAST,
  });
};
