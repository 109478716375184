import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

/* Import all ducks reducer */
//import pokeReducer from "./pokeDucks"
import authReducer, { getActiveAuth } from "./authDucks";
import userReducer from "./userDucks";
import venueReducer from "./venueDucks";
import stripeReducer from "./stripeDucks";
import subMenuReducer from "./subMenuDucks";
import qrFlowReducer from "./qrFlowDucks";
import scanReducer from "./scanDucks";
import customersReducer from "./customersDucks";
import toastReducer from "./toastDucks";
import emailsReducer from "./emailsDucks";
import bigQueryReducer from "./bigQueryDucks";
import sideNavMobileReducer from "./sideNavMobileDuks";

/* Combine all ducks */
const rootReducer = combineReducers({
    //pokemons: pokeReducer,
    auth: authReducer,
    user: userReducer,
    venue: venueReducer,
    stripe: stripeReducer,
    qrFlow: qrFlowReducer,
    subMenu: subMenuReducer,
    scan: scanReducer,
    customers: customersReducer,
    toast: toastReducer,
    emails: emailsReducer,
    bigQuery: bigQueryReducer,
    sideNavMobile: sideNavMobileReducer,
});

/* Configuration to extension redux in google chrome */
const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

/* Configuration to store */
export default function generateStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );

    // Verify if exist auth
    getActiveAuth()(store.dispatch);

    return store;
}