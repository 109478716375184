import React from "react";
import "./HelpButton.scss";
import { Row, Col, Icon, Button } from "react-materialize";
import M from "materialize-css";
import IphoneModal from "components/iphoneModal/IphoneModal";

import { useSelector } from "react-redux";

import { analytics } from "controller/firebase";

import { useTranslation } from "react-i18next";

/* import { CSVLink } from "react-csv";
import { GetDataUsersAnalytics } from "../../helpers/GetDataUsersAnalytics"; */

const HelpButton = () => {
  const { t } = useTranslation();

  const modalId = "help-modal";

  // Redux state
  const pricesType = useSelector((store) => store.user.data.pricesType);

  /* const [data, setData] = React.useState([]);
  const [headers, setHeaders] = React.useState([]); */

  const openModal = async () => {
    /* GetDataUsersAnalytics({ maxUsers: 35, maxDays: 10 }).then((res) => {
      setData(res.data);
      setHeaders(res.headers);
    }); */

    const elem = document.getElementById(modalId);
    var instance = M.Modal.getInstance(elem);
    instance.open();
  };

  return (
    <div>
      <IphoneModal
        button={
          <Button
            className="o-btn o-help-btn"
            tooltip={t("helpWord")}
            tooltipOptions={{
              position: "left",
              html: "id=help",
            }}
            node="button"
            waves="light"
            onClick={() => {
              analytics.logEvent("help", {
                method: "none",
                prices_type: "none",
              });
              openModal();
            }}
          >
            <Icon left>help_outline</Icon>
            {t("helpButtonTitle")}
          </Button>
        }
        title={t("helpButtonModalTitle")}
        content={
          <div className="o-content">
            <Row>
              <Col m={6} s={12} className="center-align">
                <Button
                  onClick={() => {
                    analytics.logEvent("help", {
                      method: "whatsapp",
                      prices_type: pricesType,
                    });
                    window.open("https://wa.me/+441244646017", "_blank");
                  }}
                  className="o-btn o-btn-modal"
                >
                  <i className="material-icon left fab fa-whatsapp"></i>
                  {t("whatsAppWord")}
                </Button>
              </Col>

              <Col m={6} s={12} className="center-align">
                <Button
                  onClick={() => {
                    analytics.logEvent("help", {
                      method: "email",
                      prices_type: pricesType,
                    });
                    window.open("mailto: hello@supermenuapp.com", "_blank");
                  }}
                  className="o-btn o-btn-modal"
                >
                  <Icon left>email</Icon>
                  {t("emailWord")}
                </Button>
              </Col>
              {/* <Col m={6} s={12} className="center-align">
                <CSVLink data={data} headers={headers}>
                  Download me
                </CSVLink>
              </Col> */}
            </Row>
          </div>
        }
        modalId={modalId}
      />
    </div>
  );
};

export default HelpButton;
