import ReactDOMServer from "react-dom/server";
import M from "materialize-css";
//import { Button } from "react-materialize";

export const SUCCESS = "success";
export const ERROR = "error";
export const INFO = "info";

export const openToast = ({
  content = "",
  importantContent = "",
  type = INFO,
  time = 4000,
}) => {
  let newMessage;
  const contentString = ReactDOMServer.renderToString(content);
  const importantContentString =
    ReactDOMServer.renderToString(importantContent);

  /* const closeButton = ReactDOMServer.renderToString(
    <Button id="btn-close-toast">
      <i className="material-icons">close</i>
    </Button>
  ); */
  if (type === SUCCESS) {
    //newMessage = `<div><h6>Success</h6>${closeButton}${contentString}${importantContentString}</div>`;
    newMessage = `<div>${contentString}${importantContentString}</div>`;
  } else if (type === ERROR) {
    //newMessage = `<div><h6>Error</h6>${closeButton}${contentString}${importantContentString}</div>`;
    newMessage = `<div>${contentString}${importantContentString}</div>`;
  } else {
    //newMessage = `<div><h6>Info</h6>${closeButton}${contentString}${importantContentString}</div>`;
    newMessage = `<div>${contentString}${importantContentString}</div>`;
  }

  M.toast({ html: newMessage, displayLength: time, classes: type });

  /* document
    .getElementById("btn-close-toast")
    .addEventListener("click", function () {
      const toastElement = document.querySelector(".toast");
      const toastInstance = M.Toast.getInstance(toastElement);
      toastInstance.dismiss();
    }); */
};
