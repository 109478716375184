/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import { db, functions, analytics } from "../controller/firebase";
import { loadStripe } from "@stripe/stripe-js";
import { TimeConverter } from "../helpers/TimestampToDate";
import { DiffInMonths, DiffInDays } from "../helpers/GetDifferenceBetweenDates";
import { SendCustomersEmails } from "../helpers/SendCustomersEmails";

import { saveToastPlan } from "./toastDucks";

import { openToast, ERROR } from "../constant/Toast";

import { updateClearFields } from "./emailsDucks";

import i18n from "i18next";
import { ListFirebaseCustomError } from "../helpers/ListFirebaseCustomErrors";
import { reloadDataToken } from "./userDucks";
import { isBrowser } from "react-device-detect";

/* Constants or States */
const initData = {
  loading: true,
  isPlan: true,
  isFreeTrial: false,
  leftTrialDays: null,
  openCustomerPortal: false,
  billing: {},
  invoices: {},
  plans: {},
  feedbackProgram: true,
  currentPlan: {},
};

const LOADING_STRIPE_START = "LOADING_STRIPE_START";
const LOADING_STRIPE_END = "LOADING_STRIPE_END";

const GET_CURRENT_PLAN_EXIT = "GET_CURRENT_PLAN_EXIT";
const GET_CURRENT_PLAN_ERROR = "GET_CURRENT_PLAN_ERROR";

const UPDATE_STRIPE_EXIT = "UPDATE_STRIPE_EXIT";
const UPDATE_STRIPE_ERROR = "UPDATE_STRIPE_ERROR";

const GET_PLANS_EXIT = "GET_PLANS_EXIT";
const GET_PLANS_ERROR = "GET_PLANS_ERROR";

const GET_INVOICES_EXIT = "GET_INVOICES_EXIT";
const GET_INVOICES_ERROR = "GET_INVOICES_ERROR";

const UPDATE_EMAILS_EXIT = "UPDATE_EMAILS_EXIT";
const UPDATE_EMAILS_ERROR = "UPDATE_EMAILS_ERROR";

const RESTART_STRIPE = "RESTART_STRIPE";

/* Reducer (Save call API in constant) */
export default function stripeReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_STRIPE_START:
      return { ...state, loading: true };
    case LOADING_STRIPE_END:
      return { ...state, loading: false };
    case GET_CURRENT_PLAN_EXIT:
      return {
        ...state,
        currentPlan: action.payload.plan,
        isPlan: action.payload.isPlan,
        leftTrialDays: action.payload.leftTrialDays,
        isFreeTrial: action.payload.isFreeTrial,
        openCustomerPortal: action.payload.openCustomerPortal,
        loading: false,
      };
    case GET_CURRENT_PLAN_ERROR:
      return { ...state, loading: false };
    case UPDATE_STRIPE_EXIT:
      return { ...state, ...action.payload, loading: false };
    case UPDATE_STRIPE_ERROR:
      return { ...state, loading: false };
    case GET_PLANS_EXIT:
      return {
        ...state,
        plans: [...action.payload.data],
        feedbackProgram: action.payload.feedbackProgram,
        loading: false,
      };
    case GET_PLANS_ERROR:
      return { ...state, loading: false };
    case GET_INVOICES_EXIT:
      return { ...state, invoices: [...action.payload], loading: false };
    case GET_INVOICES_ERROR:
      return { ...state, loading: false };
    case UPDATE_EMAILS_EXIT:
      return { ...state };
    case UPDATE_EMAILS_ERROR:
      return { ...state, loading: false };
    case RESTART_STRIPE:
      return { ...initData };
    default:
      return { ...state };
  }
}

/* Actions (Calls API) */
export const getCurrentPlanData = () => async (dispatch, getState) => {
  // Init loading
  dispatch({
    type: LOADING_STRIPE_START,
  });

  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;
    const { usage, pricesType } = getState().user.data;

    try {
      // Cancel plans
      const plans = await db
        .collection("users")
        .doc(uid)
        .collection("subscriptions")
        .get();
      plans.forEach(async (sub) => {
        if (sub.data().metadata["paymentUser"] !== "true") {
          if (sub.data().cancel_at_period_end !== true) {
            const data = {
              subId: sub.id,
            };
            const cancelSubscription =
              functions.httpsCallable("cancelSubscription");
            cancelSubscription(data);
          }
        }
      });

      db.collection("users")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          // In this implementation we only expect one active or trialing subscription to exist.
          if (snapshot.docs.length > 0) {
            let doc = snapshot.docs[0];
            let openCustomerPortal = false;

            // Validate if the use have an active plan
            snapshot.forEach(async (sub) => {
              if (sub.data().status === "active") {
                openCustomerPortal = true;
                doc = sub;
                return;
              }
            });

            // Validate if the use have an active plan
            snapshot.forEach(async (sub) => {
              if (sub.data().status === "active") {
                doc = sub;
                return;
              }
            });

            const name = doc.data().items[0].price.product.name;
            const description = doc.data().items[0].price.product.description;
            const price = doc.data().items[0].price.unit_amount;
            const id = doc.data().items[0].price.id;
            const interval = doc.data().items[0].plan.interval;
            let contacts = doc.data().items[0].price.product.metadata.contacts;
            let emails = "";

            if (interval === "month") {
              emails = doc.data().items[0].price.product.metadata.emails_1;
            } else {
              let today = new Date();
              //today.setDate(today.getDate() + 35);

              const dateCreated = doc.data().created.toDate();
              const currentMonth = DiffInMonths(today, dateCreated);
              emails =
                doc.data().items[0].price.product.metadata[
                  `emails_${currentMonth + 1}`
                ];
            }

            const plan = {};

            plan.name = name;
            plan.description = description;
            plan.price = price;
            plan.id = id;
            plan.subscriptionId = doc.id;
            plan.interval = interval;
            plan.contacts = contacts;
            plan.emails = emails;

            // Validate if the free trial is close to end
            let leftTrialDays = null;
            let isFreeTrial = false;
            if (doc.data().status === "trialing") {
              const diffInDays = DiffInDays(
                new Date(doc.data().trial_end.toDate()),
                new Date()
              );

              if (diffInDays <= 7) {
                // Show toast to upgrade plan
                document
                  .getElementById("toast-upgrade-plan-limit-date")
                  .classList.remove("hide");
                saveToastPlan({ plan: true })(dispatch, () => getState());

                leftTrialDays = diffInDays;
              } else {
                // Hide toast to upgrade plans
                document
                  .getElementById("toast-upgrade-plan-limit-date")
                  .classList.add("hide");
                saveToastPlan({ plan: false })(dispatch, () => getState());

                leftTrialDays = diffInDays;
              }

              isFreeTrial = true;
            }

            // Update pay now if the user have the second or third prices type
            if (pricesType === 2 || pricesType === 3) {
              const idVenue = getState().venue.data[0].id;

              await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(idVenue)
                .update({
                  payNow: false,
                });

              // Reload data token
              reloadDataToken()(dispatch);
            }

            dispatch({
              type: GET_CURRENT_PLAN_EXIT,
              payload: {
                plan: plan,
                isPlan: true,
                isFreeTrial: isFreeTrial,
                leftTrialDays: leftTrialDays,
                openCustomerPortal: openCustomerPortal,
              },
            });
          } else {
            // Validate that the user have payed one plan at last once
            let openCustomerPortal = false;
            const oldPlans = await db
              .collection("users")
              .doc(uid)
              .collection("subscriptions")
              .where("metadata.paymentUser", "==", "true")
              .get();

            if (oldPlans.docs.length > 0) {
              openCustomerPortal = true;
            }

            const plan = {};

            // Validate if steps more than 30 days
            const diffInDays = DiffInDays(new Date(), new Date(usage.created));

            if (diffInDays > 30) {
              // Update usage of free plan
              await db
                .collection("users")
                .doc(uid)
                .collection("usage")
                .doc("free")
                .set({
                  contacts: 100,
                  emails_1: 100,
                  created: new Date(),
                });

              plan.name = "Free";
              plan.contacts = 100;
              plan.emails = 100;
            } else {
              plan.name = "Free";
              plan.contacts = usage.contacts;
              plan.emails = usage.emails_1;
            }

            // Validate if is a new user
            const newUser = getState().user.newUser;

            // Update pay now if the user have the second or third prices type
            if (pricesType === 2 || pricesType === 3) {
              if (!newUser) {
                // Show toast unexpected error reload
                document
                  .getElementById("toast-upgrade-plan-end-date")
                  .classList.remove("hide");
                saveToastPlan({ plan: true })(dispatch, () => getState());
              }
            }

            dispatch({
              type: GET_CURRENT_PLAN_EXIT,
              payload: {
                plan: plan,
                isPlan: newUser ? true : false,
                isFreeTrial: false,
                leftTrialDays: null,
                openCustomerPortal: openCustomerPortal,
              },
            });
          }
        });
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L271 @ stripeDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: GET_CURRENT_PLAN_ERROR,
      });
    }
  }
};

export const updateEmailsSent =
  ({
    emailsSent = 0,
    emails = [],
    message = "",
    subject = "",
    restaurant = "",
    venueId = "",
  }) =>
  async (dispatch, getState) => {
    // Init loading
    dispatch({
      type: LOADING_STRIPE_START,
    });

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      const email = JSON.parse(localStorage.getItem("auth")).email;

      try {
        const { currentPlan } = getState().stripe;
        const currentEmails = currentPlan.emails;
        const newCurrentsEmails = parseInt(currentEmails) - emailsSent;

        // Send emails
        const resEmail = await SendCustomersEmails({
          email: email,
          emails: emails,
          subject: subject,
          message: message,
          restaurant: restaurant,
          userId: uid,
          venueId: venueId,
        });

        // Update to clear fields of message ans subject
        updateClearFields({ clear: true })(dispatch);

        // Validate if the emails sent
        if (resEmail === "Done") {
          if (currentPlan.subscriptionId) {
            const res = await db
              .collection("users")
              .doc(uid)
              .collection("subscriptions")
              .doc(currentPlan.subscriptionId)
              .get();
            let items = [];

            // Validate interval of plan
            if (currentPlan.interval === "month") {
              items = {
                ...res.data().items[0],
                price: {
                  ...res.data().items[0].price,
                  product: {
                    ...res.data().items[0].price.product,
                    metadata: {
                      ...res.data().items[0].price.product.metadata,
                      emails_1: newCurrentsEmails,
                    },
                  },
                },
              };
            } else {
              let today = new Date();
              //today.setDate(today.getDate() + 35);
              // Get current month
              const dateCreated = res.data().created.toDate();
              const currentMonth = DiffInMonths(today, dateCreated);

              items = {
                ...res.data().items[0],
                price: {
                  ...res.data().items[0].price,
                  product: {
                    ...res.data().items[0].price.product,
                    metadata: {
                      ...res.data().items[0].price.product.metadata,
                      [`emails_${currentMonth}`]: newCurrentsEmails,
                    },
                  },
                },
              };
            }

            await db
              .collection("users")
              .doc(uid)
              .collection("subscriptions")
              .doc(currentPlan.subscriptionId)
              .update({
                items: [items],
              });

            // Reload data token
            reloadDataToken()(dispatch);
          } else {
            await db
              .collection("users")
              .doc(uid)
              .collection("usage")
              .doc("free")
              .update({
                emails_1: newCurrentsEmails,
              });

            // Reload data token
            reloadDataToken()(dispatch);
          }

          getCurrentPlanData()(dispatch, () => getState());

          dispatch({
            type: UPDATE_EMAILS_EXIT,
          });
        } else {
          dispatch({
            type: UPDATE_EMAILS_ERROR,
          });
        }
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L344 @ stripeDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: UPDATE_EMAILS_ERROR,
        });
      }
    }
  };

export const getPlansStripe = () => async (dispatch, getState) => {
  // Init loading
  dispatch({
    type: LOADING_STRIPE_START,
  });

  try {
    let pricesType = getState().user.data.pricesType;
    // Validate if exist
    if (!pricesType) {
      pricesType = 6;
    }

    let data = [];
    const lang = i18n.language === "es" ? i18n.language : "en";

    const feedbackProgram = await db
      .collection("limiters")
      .doc("typeForm")
      .get();

    const res = await db
      .collection("products")
      .orderBy("order")
      .where("active", "==", true)
      .where("pricesType", "==", 5)
      .where("language", "==", lang)
      .get();

    for await (let plan of res.docs) {
      // Get id and price of the plan
      const res = await db
        .collection("products")
        .doc(plan.id)
        .collection("prices")
        .where("active", "==", true)
        .get();
      res.forEach(async (price) => {
        data = [
          ...data,
          {
            ...plan.data(),
            price: price.data().unit_amount,
            planId: price.id,
            interval: price.data().interval,
          },
        ];
      });
    }

    dispatch({
      type: GET_PLANS_EXIT,
      payload: {
        data: data,
        feedbackProgram: feedbackProgram.data().active,
      },
    });

    // Save in the browser
    localStorage.setItem("plans", JSON.stringify(data));
  } catch (error) {
    console.log(error);
    // Show toast unexpected error reload
    document.getElementById("toast-unexpected-error").classList.remove("hide");
    // Save analytics
    console.log(error);
    analytics.logEvent("new_error", {
      description: `L407 @ stripeDucks.js | ${error.code} - ${error.message}`,
    });

    dispatch({
      type: GET_PLANS_ERROR,
    });
  }
};

export const getInvoicesStripe = () => async (dispatch) => {
  // Init loading
  dispatch({
    type: LOADING_STRIPE_START,
  });

  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;

    try {
      let data = [];

      const res = await db
        .collection("users")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .get();

      for await (let plan of res.docs) {
        // Get id and price of the plan
        const res = await db
          .collection("users")
          .doc(uid)
          .collection("subscriptions")
          .doc(plan.id)
          .collection("invoices")
          .get();
        res.forEach((invoice) => {
          const date = TimeConverter(invoice.data().created);
          data = [
            ...data,
            {
              state: invoice.data().status,
              date: date,
              price: invoice.data().amount_paid,
              url: invoice.data().invoice_pdf,
            },
          ];
        });
      }

      if (data.length > 0) {
        dispatch({
          type: GET_INVOICES_EXIT,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_INVOICES_ERROR,
        });
      }
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L454 @ stripeDucks.js | ${error.code} - ${error.message}`,
      });

      dispatch({
        type: GET_INVOICES_ERROR,
      });
    }
  }
};

// Open checkout page
export const sendToCheckout = (planId) => async (dispatch) => {
  // Init loading
  dispatch({
    type: LOADING_STRIPE_START,
  });

  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;

    let redirect;

    if (isBrowser) {
      redirect = `/#/${uid}/dashboard/account/products`;
    } else {
      redirect = `/#/${uid}/dashboard/account/products/mobile`;
    }

    try {
      db.collection("users")
        .doc(uid)
        .collection("checkout_sessions")
        .add({
          price: planId,
          trial_from_plan: false,
          success_url: window.location.origin + redirect,
          cancel_url: window.location.origin + redirect,
          metadata: {
            paymentUser: true,
          },
        })
        .then((docRef) => {
          docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (error) {
              // Show toast unexpected error reload
              document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide");
              // Save analytics
              analytics.logEvent("new_error", {
                description: `L493 @ stripeDucks.js | ${error.code} - ${error.message}`,
              });

              return;
            }

            if (sessionId) {
              const stripe = await loadStripe(
                "pk_live_51IgXeYLsw7UaIUVA4RXeYsSsYrJuD7IqO6vYlbuY3QoPHv5YQ5GmivMNTqipuJPkuXvq2YaaWqKEKiNee01b93gB00dUDqKf4Y"
              );
              await stripe.redirectToCheckout({ sessionId });

              // Finish loading
              dispatch({
                type: LOADING_STRIPE_END,
              });
            }
          });
        });
    } catch (error) {
      const errorCode = error.code.split("/");
      const errorMessage = ListFirebaseCustomError({ code: errorCode[1] });

      // Save error
      openToast({
        content: <p> {errorMessage} </p>,
        type: ERROR,
      });

      if (errorMessage === "An unexpected error occurred, please try again") {
        analytics.logEvent("new_error", {
          description: `L514 @ stripeDucks.js | ${error.code} - ${error.message}`,
        });
      } else {
        analytics.logEvent("new_error", {
          description: `L514 @ stripeDucks.js | ${errorMessage}`,
        });
      }

      // Finish loading
      dispatch({
        type: LOADING_STRIPE_END,
      });
    }
  }
};

// Open customer portal
export const sendToCustomerPortal = () => async (dispatch) => {
  // Init loading
  dispatch({
    type: LOADING_STRIPE_START,
  });

  if (localStorage.getItem("auth")) {
    // Get user's uid from browser
    const uid = JSON.parse(localStorage.getItem("auth")).uid;

    let redirect;

    if (isBrowser) {
      redirect = `/#/${uid}/dashboard/account/products`;
    } else {
      redirect = `/#/${uid}/dashboard/account/products/mobile`;
    }

    try {
      const functionRef = functions.httpsCallable(
        "ext-firestore-stripe-subscriptions-createPortalLink"
      );
      const { data } = await functionRef({
        returnUrl: window.location.origin + redirect,
      });
      window.location.assign(data.url);

      // Finish loading
      dispatch({
        type: LOADING_STRIPE_END,
      });
    } catch (error) {
      // Show toast unexpected error reload
      document
        .getElementById("toast-unexpected-error")
        .classList.remove("hide");
      // Save analytics
      analytics.logEvent("new_error", {
        description: `L549 @ stripeDucks.js | ${error.code} - ${error.message}`,
      });

      // Finish loading
      dispatch({
        type: LOADING_STRIPE_END,
      });
    }
  }
};

// Reset stripe
export const restartDataStripe = () => async (dispatch) => {
  dispatch({
    type: RESTART_STRIPE,
  });
};
