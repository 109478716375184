export function ConvertUrlToBase64(url) {
    return new Promise(resolve => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.onload = function() {
                const reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        } catch (error) {
            console.log(error);
        }
    });
}