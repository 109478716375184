export function DiffInYears(dateFrom, dateTo) {
    const diff = Math.floor(dateFrom.getTime() - dateTo.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    return (years);
}

export function DiffInMonths(dateFrom, dateTo) {
    const diff = Math.floor(dateFrom.getTime() - dateTo.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);

    return (months);
}

export function DiffInDays(dateFrom, dateTo) {
    const diff = Math.floor(dateFrom.getTime() - dateTo.getTime());
    const day = 1000 * 60 * 60 * 24;

    const days = Math.floor(diff / day);
    return (days);
}

export function DiffInHours(dateFrom, dateTo) {
    const diff = Math.floor(dateFrom.getTime() - dateTo.getTime());
    const hour = 1000 * 60 * 60;

    const hours = Math.floor(diff / hour);
    return (hours);
}