import { openToast, SUCCESS, ERROR } from "../constant/Toast";
import { functions, analytics } from "../controller/firebase";

import i18n from "i18next";

export function SendCustomersEmails({
  email = "",
  emails = [],
  subject = "",
  message = "",
  restaurant = "",
  userId = "",
  venueId = "",
}) {
  return new Promise(async (resolve, reject) => {
    // Call function
    const sendEmails = functions.httpsCallable("sendQRCustomersEmail");

    // Convert string into HTML object
    const html = document.createElement("div");
    html.innerHTML = message;

    // Create unsubscribe message
    const unsubscribe = `${window.origin}/#/unsubscribe/${userId}/${venueId}`;

    const images = html.getElementsByTagName("img");
    let imagesBase64 = [];

    // Get all src from images
    for (let i = 0; i < images.length; i++) {
      imagesBase64 = [
        ...imagesBase64,
        {
          content: images[i].src.replace("data:image/png;base64,", ""),
          content_id: `image${i}`,
          filename: `image${i}.png`,
          type: "image/png",
          disposition: "inline",
        },
      ];
      images[i].src = `cid:image${i}`;
    }

    // Change align classes to style
    const elementsP = html.getElementsByTagName("p");
    for (let i = 0; i < elementsP.length; i++) {
      if (elementsP[i].classList.contains("ql-align-center")) {
        elementsP[i].style.cssText = "text-align: center";
      } else if (elementsP[i].classList.contains("ql-align-right")) {
        elementsP[i].style.cssText = "text-align: right";
      } else if (elementsP[i].classList.contains("ql-align-justify")) {
        elementsP[i].style.cssText = "text-align: justify";
      }
    }
    const elementsLI = html.getElementsByTagName("li");
    for (let i = 0; i < elementsLI.length; i++) {
      if (elementsLI[i].classList.contains("ql-align-center")) {
        elementsLI[i].style.cssText = "text-align: center";
      } else if (elementsLI[i].classList.contains("ql-align-right")) {
        elementsLI[i].style.cssText = "text-align: right";
      } else if (elementsLI[i].classList.contains("ql-align-justify")) {
        elementsLI[i].style.cssText = "text-align: justify";
      }
    }

    // Delete user email from the array if exist
    const filterEmails = emails.filter((item) => item !== email);

    const data = {
      email: email,
      emails: filterEmails,
      subject: subject,
      restaurant: restaurant,
      message: html.innerHTML,
      unsubscribe: unsubscribe,
      attachments: imagesBase64,
    };

    sendEmails(data)
      .then(async function (result) {
        if (result.data.res[0].statusCode === 202) {
          resolve("Done");
          openToast({
            content: <p>{i18n.t("toastSuccessEmailSent")}</p>,
            type: SUCCESS,
          });
          analytics.logEvent("toast", {
            description: `L86 @ QrActions.js | Your email was sent`,
          });
        } else {
          resolve("Error");
          openToast({
            content: <p>{i18n.t("toastErrorEmailNotSent")}</p>,
            type: ERROR,
          });
        }
      })
      .catch(function (error) {
        openToast({
          content: <p>{i18n.t("toastErrorEmailNotSent")}</p>,
          type: ERROR,
        });
        analytics.logEvent("new_error", {
          description: `L101 @ SendCustomersEmails.js | ${error.code} - ${error.message}`,
        });
        resolve("Error");
      });
  });
}
