export const translationEs = {
    /* --------------- WORDS --------------- */
    superMenuWord: "SuperMenu",
    loginWord: "Iniciar Sesión",
    whatsAppWord: "WhatsApp",
    instagramWord: "Instagram",
    tikTokWord: "TikTok",
    twitterWord: "Twitter",
    facebookWord: "Facebook",
    emailWord: "Email",
    orWord: "Ó",
    passWord: "Contraseña",
    signInWord: "Iniciar Sesión",
    signUpWord: "Crear cuenta",
    recoverWord: "Recuperar",
    pageWord: "Página",
    removeWord: "Remover",
    changeWord: "Cambiar",
    downloadWord: "Descargar",
    nameWord: "Nombre",
    saveWord: "Guardar",
    printWord: "Imprimir",
    sendWord: "enviar",
    adWord: "Anuncio",
    congratulationsWord: "Felicitaciones",
    cancelWord: "Cancelar",
    continueWord: "Continuar",
    addressWord: "Dirección",
    socialsWord: "Redes Sociales",
    timezoneWord: "Zona horaria",
    companyWord: "Empresa",
    freeWord: "Gratis" /*-Q: Donde usamos esto? Free can be translated to Gratis or Gratuito dependiendo-*/,
    planWord: "Plan",
    plansWord: "Planes",
    usageWord: "Uso",
    menuWord: "Menú",    
    menusWord: "Menús",
    helpWord: "Ayuda",
    accountWord: "Cuenta",
    codeWord: "Código",
    reportsWord: "Reportes",
    acceptWord: "Aceptar",
    cookiesWord: "Cookies",
    monthWord: "mes",
    todayWord: "Hoy",
    yesterdayWord: "Ayer",
    customWord: "Personalizado",
    closeWord: "Cerrar",
    previewWord: "Vista Previa",
    customizeWord: "Personalizar",
    
    /* --------------- PAGES --------------- */

        /* --- Home --- */
        createQRMenu: "Crear Código QR Gratuito",

        homeTitle: "Menú QR Gratis",
        homeSubTitlePrimary: "Creado para Restaurantes, Bares, & Cafés",
        homeSubTitle: "Sube un PDF o imágenes de tu menú, e imprime tu código QR en <bold>60 segundos</bold>",

        tag1: "100% Gratis",
        tag2: "Nunca Expira",
        tag3: "Scans Ilimitados",
        tag4: "Menú Dinámico",
        tag5: "Reportes",
        tag6: "Soporte al Cliente",

        step1: "Escanea Tu Código QR",
        step2: "Anuncio de 3 Segundos",
        step3: "Observa tu Menú",

        section1Title: "Mide el Número de Scans",
        section1SubTitle: "Reportes diarios elegantes y sencillos",

        section2Title: "Crea tu SuperMenu en 60 segundos",
        section2SubTitle: "En sólo 2 simples pasos",
        section2Content1: "Sube Tu Menú",
        section2Content2: "Imprime Tu Código",

        section3Title: "Evoluciona con SuperMenu",
        section3SubTitle: "Creado para restaurantes, bares, y cafés en todo el mundo",
        section3Content1: "Únete a miles de restaurantes alrededor del mundo",

        section4Title: "Crea tu SuperMenu Hoy Mismo",
        section4SubTitle: "Es fácil y gratis",
        section4Content1: "Servicio al Cliente",
        section4Content2: "Nuestro equipo está para ayudarte de Lunes a Domingo entre las 8:00 a.m. y las 6:00 p.m. Hora del Este.",

        /* --- Home-2 --- */        

        /* --- Login --- */
        signInTitle: "Iniciar Sesión",
        signUpTitle: "Crear Cuenta",
        recoverPass: "Recuperar Contraseña",

        signInSubTitle: "Utiliza el <bold>email que ingresaste para registrarte</bold> en SuperMenu",
        signUpSubTitle: "<bold>Gratis para siempre</bold> con anuncions <bold>o</bold> $15 por mes sin anuncios",        

        googleButton: "Continuar con Google",

        rememberPass: "Recuerdas tu contraseña?",
        forgotPass: "Olvidaste tu contraseña?",
        recoverNow: "Recuperar ahora",

        createAccount: "Crear nueva cuenta",
        alreadyAccount: "Ya tienes una cuenta?",
        signInNow: "Iniciar sesión ahora",

        /* --- Add Menu --- */
        addMenuTitle: "Añade Tu Menú",

        addMenuToast1Content: "<bold>Actualiza tu menú tantas veces como quieras.</bold> Tu código <bold>siempre mostrará el menú más reciente</bold> en el próximo scan. <br> <br> Puedes cambiar el orden de las páginas una vez aparezcan.",

        addMenuToast2Title: "Consejos para subir archivos",
        addMenuToast2Content1: "Si quieres convertir cualquier archivo <bold>a un PDF,</bold> haz click en <a>este link</a>",
        addMenuToast2Content2: "Si quieres <bold>unir o mezclar PDFs,</bold> haz click en <a>este link</a>",
        addMenuToast2Content3: "Si quieres reducir el <bold>tamaño del PDF,</bold> haz click en <a>este link</a>",
        addMenuToast2Content4: "Si quieres convertir un archivo <bold>a una IMAGEN,</bold> haz click en <a>este link</a>",

        chooseFiles: "Escoger archivos",
        addFiles: "Añadir archivos",
        dropFilesHere: "O arrastra archivos aquí",

        emptySectionTitle: "Tu menú saldrá aquí",
        emptySectionDescription: "Por favor añade tu menú y este aparecerá aquí",

            // Modal update custom url
        modalPreviewMobile: "Tu Menú",
            
        /* --- Get QR Code --- */
        getQRCode: "Imprime Tu Código",

        getQRCodeToast1Content1: "Puedes editar e imprimir un nuevo código tanto como quieras. <bold>Los códigos ya impresos continuarán funcionando junto a los nuevos códigos y los reportes seguirán funcionando normalmente.</bold>",
        getQRCodeToast2Title: "Consejos para subir archivos",    
        getQRCodeToast2Content1: "Si quieres convertir un archivo <bold>a una IMAGEN,</bold> haz click en <a>este link</a>",
        getQRCodeToast3Title: "Consejos para descargar tu menú",    
        getQRCodeToast3Content1: "Toque y mantenga presionado el código QR para guardar la imagen",

        choosePattern: "Escoge un diseño",
        chooseColor: "Escoge un color",
        chooseCorner: "Escoge una esquina",
        chooseStyle: "Escoge un estilo",
        chooseImage: "Escoge una imagen de centro (opcional)",

        chooseImageButton: "Escoger imágen",
        dropImageHere: "O arrasta una imágen aquí",

            // Modal update custom url
            modalUpdateUrlTitle: "URL Personalizada",
            // Modal actions
            modalActionsTitle: "Tu Código QR",
            // Modal create account
            modalCreateAccountSubtitle: "Ingresa un email y contraseña para guardar tu código QR code en tu cuenta <br> <br> <bold>Sólo tienes que hacer esto una vez </bold>",
            // Modal custom qr code
            modalCustomQrCode: "Personalización",

        /* --- Reports Scan --- */
        reportsScanTitle: "Scans",

        reportsScanToast1Content: "Los reportes de scans se actualizan una vez al día. <bold>Los scans de hoy aparecerán mañana.</bold>",
        
        numberScans: "Número de Scans",
        last7Days: "Últimos 7 días",
        lastWeek: "Última semana",
        last30Days: "Últimos 30 días",
        lastMonth: "Último mes calendario",

        sun: "Dom",
        mon: "Lun",
        tue: "Mar",
        wed: "Mie",
        thu: "Jue",
        fri: "Vie",
        sat: "Sab",

        /* --- Page 404 --- */
        pageNotFound: "Esta página no pudo ser encontrada",
        goToHome: "Ir a inicio",
        
        /* --- Terms --- */
        "pt-section-privacy-title": "Política de Privacidad",
        "pt-section-privacy-text-1": "SuperMenu  proporciona códigos QR gratuitos, formularios de recopilación de datos y otros servicios a usuarios de todo el mundo. Nuestra Política de privacidad ayuda a explicar nuestras prácticas de información. Por ejemplo, hablamos sobre qué información recopilar y cómo esto le afecta. También explicamos los pasos que tomamos para proteger su privacidad, como crear SuperMenu  para que la información de contacto de los usuarios se almacene en su teléfono en todo momento y solo se comparta cuando se inscriba explícitamente en un establecimiento determinado. ",
        "pt-section-privacy-text-2": "Cuando decimos 'SuperMenu ', 'nuestro', 'nosotros' o 'nos', estamos hablando de SuperQr Ltd. Esta Política de privacidad ('Política de privacidad') se aplica a todas nuestras aplicaciones, servicios, funciones, software y sitio web (en conjunto, 'Servicios') a menos que se especifique lo contrario. ",
        "pt-section-privacy-text-3": "Lea también nuestros Términos de servicio ('Términos'), que describen los términos bajo los cuales utiliza nuestros Servicios.",
        "pt-section-privacy-information-we-collect-title": "Información que recopilamos",
        "pt-section-privacy-information-we-collect-p": "SuperMenu  recibe o recopila información cuando operamos y brindamos nuestros Servicios, incluso cuando usted instala, accede o usa nuestros Servicios",
        "pt-section-privacy-information-you-provide-title": "Información que proporciona",
        "pt-section-privacy-your-account-information": "Información de su cuenta",
        "pt-section-privacy-your-account-information-desc": "Usted proporciona su dirección de correo electrónico para crear una cuenta SuperMenu . También puede agregar otra información a su cuenta, como un nombre, logotipo y mensaje diario",
        "pt-section-privacy-your-patrons": "Tus usuarios",
        "pt-section-privacy-your-patrons-desc": "No retenemos la información de sus usuarios por más tiempo de lo requerido por la ley para fines de rastreo de contactos si la ley lo especifica y usted opta por esta función opcional. Podemos mantenerla en nuestros servidores por hasta 30 días mientras tratamos de cumplir con la regulación en evolución debido a la situación de la pandemia global. Si la información no es requerida por las autoridades 30 días después del período de retención obligatorio, la eliminamos. ",
        "pt-section-privacy-your-pictures": "Tus imágenes",
        "pt-section-privacy-your-pictures-desc": "Para ayudarlo a mostrar su menú de manera fácil y eficiente, podemos cambiar el tamaño o comprimir su menú antes de almacenarlo en nuestros servidores. Lo mismo se aplica a su logotipo.",
        "pt-section-privacy-customer-support": "Atención al cliente",
        "pt-section-privacy-customer-support-desc": "Puede proporcionarnos información relacionada con el uso de nuestros Servicios, incluidas copias de su menú, información de contacto de los usuarios y cómo contactarlo para que podamos proporcionarle soporte. Por ejemplo, puede enviarnos un correo electrónico con información relacionada con el rendimiento de nuestra aplicación u otros problemas. ",
        "pt-section-privacy-auto-collected-info-title": "Información recopilada automáticamente",
        "pt-section-privacy-usage-and-log": "Información de uso y registro",
        "pt-section-privacy-usage-and-log-desc": "Recopilamos información relacionada con el servicio, de diagnóstico y de rendimiento. Esto incluye información sobre su actividad (por ejemplo, cómo usa nuestros Servicios, cómo interactúa con otros usando nuestros Servicios y similares), archivos de registro y registros e informes de diagnóstico, fallos, sitios web y rendimiento. ",
        "pt-section-privacy-device-and-connection-info": "Información del dispositivo y la conexión",
        "pt-section-privacy-device-and-connection-info-desc": "Recopilamos información específica del dispositivo cuando instala, accede o usa nuestros Servicios. Esto incluye información como el modelo de hardware, información del sistema operativo, información del navegador , Dirección IP, información de la red móvil e identificadores de dispositivos. ",
        "pt-section-privacy-cookies": "Cookies.",
        "pt-section-privacy-cookies-desc": "Usamos cookies para operar y proporcionar nuestros Servicios, incluso para proporcionar nuestros Servicios basados ​​en la web, mejorar sus experiencias, comprender cómo se utilizan nuestros Servicios y personalizar nuestros Servicios . Por ejemplo, utilizamos cookies para proporcionar SuperMenu  para la web y el escritorio y otros servicios basados ​​en la web. También podemos utilizar cookies para comprender cuáles de nuestras preguntas frecuentes son más populares y para mostrarle contenido relevante relacionado con nuestros Servicios. Además, podemos use cookies para recordar sus opciones, como sus preferencias de idioma, nombre y teléfono para registrarse, y de otro modo para personalizar nuestros Servicios para usted. Obtenga más información sobre cómo usamos las cookies para brindarle nuestros Servicios. ",
        "pt-section-privacy-third-party-info-title": "Información de terceros",
        "pt-section-privacy-third-party-providers": "Proveedores de terceros",
        "pt-section-privacy-third-party-providers-desc": "Trabajamos con proveedores externos para ayudarnos a operar, proporcionar, mejorar, comprender, personalizar, respaldar y comercializar nuestros Servicios. Por ejemplo, trabajamos con empresas para distribuir nuestras aplicaciones web, proporcionar nuestra infraestructura, entrega y otros sistemas, suministrar información de mapas y lugares, procesar pagos, ayudarnos a comprender cómo las personas usan nuestros Servicios y comercializar nuestros Servicios. Estos proveedores pueden proporcionarnos información sobre en determinadas circunstancias; por ejemplo, las tiendas de aplicaciones pueden proporcionarnos informes para ayudarnos a diagnosticar y solucionar problemas de servicio. ",
        "pt-section-privacy-third-party-services": "Servicios de terceros",
        "pt-section-privacy-third-party-services-desc": "Le permitimos usar nuestros Servicios en conexión con servicios de terceros. Si usa nuestros Servicios con dichos servicios de terceros, podemos recibir información sobre usted de ellos; por ejemplo, si usa el botón para compartir de SuperMenu  en un servicio de noticias para compartir un artículo de noticias con sus contactos, grupos o listas de transmisión de SuperMenu  en nuestros Servicios, o si elige acceder a nuestros Servicios a través de un operador o dispositivo móvil promoción de nuestros Servicios por parte del proveedor. Tenga en cuenta que cuando utilice servicios de terceros, sus propios términos y políticas de privacidad regirán el uso de esos servicios. ",
        "pt-section-privacy-how-use-info-title": "Cómo usamos la información",
        "pt-section-privacy-how-use-info-subtitle": "Usamos toda la información que tenemos para ayudarnos a operar, proporcionar, mejorar, comprender, personalizar, respaldar y comercializar nuestros Servicios",
        "pt-section-privacy-our-services": "Nuestros servicios",
        "pt-section-privacy-our-services-desc": "Operamos y proporcionamos nuestros Servicios, incluida la asistencia al cliente, y la mejora, reparación y personalización de nuestros Servicios. Entendemos cómo las personas utilizan nuestros Servicios, y analizamos y utilizan los información que tenemos para evaluar y mejorar nuestros Servicios, investigar, desarrollar y probar nuevos servicios y funciones, y realizar actividades de resolución de problemas. También usamos su información para responderle cuando se comunica con nosotros. Utilizamos cookies para operar, proporcionar, mejorar, comprender y personalizar nuestros Servicios. ",
        "pt-section-privacy-safety-security": "Seguridad y protección",
        "pt-section-privacy-safety-security-desc": "Verificamos las cuentas y la actividad, y promovemos la seguridad dentro y fuera de nuestros Servicios, por ejemplo, investigando actividades sospechosas o violaciones de nuestros Términos, y para asegurarnos de que nuestros Servicios sean siendo utilizado legalmente. ",
        "pt-section-privacy-comns-about-our-services": "Comunicaciones sobre nuestros servicios",
        "pt-section-privacy-comns-about-our-services-desc": "Nos comunicamos con usted acerca de nuestros Servicios y funciones y le informamos sobre nuestros términos y políticas y otras actualizaciones importantes",
        "pt-section-privacy-info-we-share-title": "Información que usted y nosotros compartimos",
        "pt-section-privacy-info-we-share-subtitle": "Usted comparte su información mientras usa y se comunica a través de nuestros Servicios, y nosotros compartimos su información para ayudarnos a operar, proporcionar, mejorar, comprender, personalizar, brindar soporte, y comercializar nuestros Servicios. ",
        "pt-section-privacy-account-info": "Información de la cuenta",
        "pt-section-privacy-account-info-desc": "Su correo electrónico, número de nombre del establecimiento, logotipo, menú, mensaje diario, estado activo de seguimiento del contrato y libro de visitas. En particular, su nombre, logotipo, menú y mensaje diario puede estar disponible para cualquier persona que utilice nuestros Servicios. ",
        "pt-section-privacy-contacts-and-others": "Tus contactos y otros",
        "pt-section-privacy-contacts-and-others-desc": "Los usuarios con los que se comunica pueden almacenar o compartir su información con otros dentro y fuera de nuestros Servicios",
        "pt-section-privacy-manage-your-info-title": "Cómo administrar su información",
        "pt-section-privacy-manage-your-info-subtitle": "Si desea administrar, cambiar, limitar o eliminar su información, le permitimos hacerlo a través de las siguientes herramientas:",
        "pt-section-privacy-change-email-pass": "Cambio de correo electrónico, contraseña, nombre, menú del logotipo y mensaje diario",
        "pt-section-privacy-change-email-pass-desc": "Puede cambiar cualquiera de los anteriores en cualquier momento en su cuenta o panel de control.",
        "pt-section-privacy-deleting-account": "Eliminando su cuenta SuperMenu ",
        "pt-section-privacy-deleting-account-desc-1": "Puede eliminar su cuenta SuperMenu  en cualquier momento (incluso si desea revocar su consentimiento para el uso de su información) enviándonos un correo electrónico a",
        "pt-section-privacy-deleting-account-desc-2": "Cuando elimine su cuenta SuperMenu , se eliminará cualquier información que ya no necesitemos para operar y brindar nuestros Servicios. Recuerde que cuando elimine su cuenta , no afecta la información que otros usuarios tienen sobre usted, como las copias que puedan tener de la información que usted les envió. ",
        "pt-section-privacy-law-and-protection-title": "Ley y protección",
        "pt-section-privacy-law-and-protection-subtitle": "Podemos recopilar, usar, preservar y compartir su información si creemos de buena fe que es razonablemente necesario para: (a) responder de conformidad con leyes o reglamentos aplicables, procesos legales o solicitudes gubernamentales; (b) hacer cumplir nuestros Términos y cualquier otro término y política aplicable, incluso para investigaciones de posibles infracciones; (c) detectar, investigar, prevenir y abordar el fraude y otros actividad, seguridad o problemas técnicos; o (d) proteger los derechos, la propiedad y la seguridad de nuestros usuarios, SuperMenu  u otros. ",
        "pt-section-privacy-updates-policy-title": "Actualizaciones de nuestra política",
        "pt-section-privacy-updates-policy-subtitle": "Podemos modificar o actualizar nuestra Política de privacidad. Le notificaremos las enmiendas a esta Política de privacidad, según corresponda, y actualizaremos la fecha de 'Última modificación' en la parte superior de esta Política de privacidad. Su uso continuado de nuestros Servicios confirma su aceptación de nuestra Política de privacidad, en su forma enmendada. Si no está de acuerdo con nuestra Política de privacidad, en su forma enmendada, debe dejar de usar nuestros Servicios. Por favor, revise nuestra Política de privacidad de vez en cuando.",
        "pt-section-privacy-contact-us-title": "Contáctenos",
        "pt-section-privacy-contact-us-subtitle": "Si tiene preguntas sobre nuestra Política de privacidad, comuníquese con nosotros",
        "pt-terms-of-service-title": "Condiciones de servicio",
        "pt-terms-of-service-subtitle": "SuperQr Ltd. ('SuperMenu ' 'nuestro,' nosotros 'o' nos ') proporciona menús QR sin contacto, formularios de recopilación de datos y otros servicios a los usuarios de todo el mundo. Lea nuestros Términos de servicio para comprender qué sucede con el uso de SuperMenu . Acepta nuestros Términos de servicio ('Términos') al instalar, acceder o usar nuestras aplicaciones, servicios, funciones, software o sitio web ( juntos, 'Servicios'). ",
        "pt-terms-of-service-about-our-services-title": "Acerca de nuestros servicios",
        "pt-terms-of-service-registration": "Registro",
        "pt-terms-of-service-registration-desc": "Debe registrarse en nuestros Servicios utilizando datos precisos, proporcionar su correo electrónico actual y, si lo cambia, actualizar este correo electrónico utilizando nuestra función de cambio de correo electrónico en la aplicación. acepta recibir mensajes de correo electrónico (de nosotros o de nuestros proveedores externos). ",
        "pt-terms-of-service-age": "Edad",
        "pt-terms-of-service-age-desc": "Debe tener al menos 18 años para usar nuestros Servicios (o la edad requerida en su país para que esté autorizado a usar nuestros Servicios sin la aprobación de los padres). Además de tener la edad mínima requerida para usar nuestros Servicios según la ley aplicable, si no tiene la edad suficiente para tener la autoridad para aceptar nuestros Términos en su país, su padre o tutor debe aceptar nuestros Términos en su nombre. ",
        "pt-terms-of-service-devices-software": "Dispositivos y software",
        "pt-terms-of-service-devices-software-desc": "Debe proporcionar ciertos dispositivos, software y conexiones de datos para utilizar nuestros Servicios, que de otro modo no proporcionamos. Mientras utilice nuestros Servicios, consiente la descarga e instalación de actualizaciones de nuestros Servicios, incluso automáticamente.",
        "pt-terms-of-service-fees-taxes": "Tasas e impuestos",
        "pt-terms-of-service-fees-taxes-desc": "Usted es responsable de todo el plan de datos del operador y otras tarifas e impuestos asociados con el uso de nuestros Servicios. Podemos cobrarle por nuestros Servicios, incluidos los impuestos aplicables. Podemos rechazar o cancelar pedidos. No proporcionamos reembolsos por nuestros Servicios, excepto según lo exija la ley. ",
        "pt-terms-of-service-user-data-title": "Política de privacidad y datos del usuario",
        "pt-terms-of-service-user-data-subtitle": "SuperMenu  se preocupa por su privacidad. La Política de privacidad de SuperMenu  describe nuestras prácticas de información (incluido el mensaje), incluidos los tipos de información que recibimos y recopilamos de usted y cómo usamos y compartir esta información. Usted acepta nuestras prácticas de datos, incluida la recopilación, el uso, el procesamiento y el intercambio de su información como se describe en nuestra Política de privacidad, así como la transferencia y el procesamiento de su información a los Estados Unidos y otros países a nivel mundial donde tenemos o usamos instalaciones, proveedores de servicios o socios, independientemente de dónde use nuestros Servicios. Usted reconoce que las leyes, regulaciones y estándares del país en el que se almacena o procesa su información pueden ser diferentes de las suyas país.",
        "pt-terms-of-service-acceptable-use": "Uso aceptable de nuestros servicios",
        "pt-terms-of-service-terms-and-policies": "Nuestras condiciones y políticas",
        "pt-terms-of-service-terms-and-policies-desc": "Debe utilizar nuestros Servicios de acuerdo con nuestros Términos y políticas publicadas. Si inhabilitamos su cuenta por una violación de nuestros Términos, no creará otra cuenta sin nuestro permiso. ",
        "pt-terms-of-service-legal-acceptable-use": "Uso legal y aceptable",
        "pt-terms-of-service-legal-acceptable-use-desc": "Debe acceder y utilizar nuestros Servicios solo con fines legales, autorizados y aceptables. No utilizará (ni ayudará a otros a utilizar) nuestros Servicios en formas que: (a) violen, se apropien indebidamente o infrinjan los derechos de SuperMenu , nuestros usuarios u otros, incluida la privacidad, publicidad, propiedad intelectual u otros derechos de propiedad; (b) sean ilegales, obscenos, difamatorios, amenazantes, intimidatorios, acosador, odioso, racial o étnicamente ofensivo, o instigar o alentar conductas que serían ilegales o inapropiadas, incluida la promoción de delitos violentos; (c) involucrar la publicación de falsedades, tergiversaciones o declaraciones engañosas; (d) hacerse pasar por alguien; (e ) implican el envío de comunicaciones ilegales o no permitidas, como mensajes masivos, mensajes automáticos, marcación automática y similares; o (f) implican cualquier uso no personal de nuestros Servicios a menos que lo autoricemos de otra manera. ",
        "pt-terms-of-service-harm-to-us-or-users": "Daño a SuperMenu  o nuestros usuarios",
        "pt-terms-of-service-harm-to-us-or-users-desc": "No debe (o ayudar a otros a) acceder, usar, copiar, adaptar, modificar, preparar trabajos derivados basados ​​en, distribuir, otorgar licencia, sublicenciar, transferir, mostrar, ejecutar o explotar nuestros Servicios de manera no permitida o no autorizada, o de formas que carguen, perjudiquen o dañen a nosotros, nuestros Servicios, sistemas, nuestros usuarios u otros, incluso que usted no debe directamente oa través de medios automatizados: (a) realizar ingeniería inversa, alterar, modificar, crear trabajos derivados, descompilar o extraer código de nuestros Servicios; (b) enviar, almacenar o transmitir virus u otro código informático dañino a través de nuestros Servicios o hacia ellos; (c) obtener o intentar obtener acceso no autorizado a nuestros Servicios o sistemas; (d) interferir o interrumpir la integridad o el desempeño de nuestros Servicios; (e) crear cuentas para nuestros Servicios a través de medios no autorizados o automatizados; (f) recopilar el información de o sobre nuestros usuarios de cualquier manera no permitida o no autorizada; (g) vender, revender, alquilar o cobrar por nuestros Servicios; o (h) distribuir o hacer que nuestros Servicios estén disponibles a través de una red donde puedan ser utilizados por múltiples dispositivos al mismo tiempo. ",
        "pt-terms-of-service-keep-account-secure": "Cómo mantener segura su cuenta",
        "pt-terms-of-service-keep-account-secure-desc": "Usted es responsable de mantener su dispositivo y su cuenta SuperMenu  seguros y protegidos, y debe notificarnos de inmediato sobre cualquier uso no autorizado o violación de seguridad de su cuenta o nuestros Servicios. ",
        "pt-terms-of-service-third-party-services-title": "Servicios de terceros",
        "pt-terms-of-service-third-party-services-subtitle": "Nuestros Servicios pueden permitirle acceder, usar o interactuar con sitios web, aplicaciones, contenido y otros productos y servicios de terceros. Tenga en cuenta que cuando utiliza servicios de terceros, sus propios términos y políticas de privacidad regirán el uso de esos servicios. ",
        "pt-terms-of-service-licenses": "Licencias",
        "pt-terms-of-service-your-rights": "Sus derechos. SuperMenu  no reclama la propiedad de la información que envía a su cuenta SuperMenu  oa través de nuestros Servicios. Debe tener los derechos necesarios sobre la información que envíe para su cuenta SuperMenu  oa través de nuestros Servicios y el derecho a otorgar los derechos y licencias en nuestros Términos. ",
        "pt-terms-of-service-our-rights": "Derechos de SuperMenu . Somos propietarios de todos los derechos de autor, marcas comerciales, dominios, logotipos, imagen comercial, secretos comerciales, patentes y otros derechos de propiedad intelectual asociados con nuestros Servicios.",
        "pt-terms-of-service-license-to-us": "Su licencia de SuperMenu . Para operar y proporcionar nuestros Servicios, usted otorga a SuperMenu  una licencia mundial, no exclusiva, libre de regalías, sublicenciable y transferible para usar, reproducir, distribuir, crear trabajos derivados, mostrar y ejecutar la información (incluido el contenido) que carga, envía, almacena, envía o recibe en nuestros Servicios oa través de ellos. Los derechos que otorga en esta licencia son para el propósito limitado de operar y proporcionar nuestros Servicios (por ejemplo, permitirnos mostrar su nombre, menú, logotipo y mensaje diario, transmitir esta información a los usuarios y de otra manera, como se describe en nuestra Política de privacidad). ",
        "pt-terms-of-service-license-to-you": "Licencia de SuperMenu  para usted. Le otorgamos una licencia limitada, revocable, no exclusiva, no sublicenciable e intransferible para utilizar nuestros Servicios, sujeto a y de acuerdo con nuestros Términos. Esta licencia tiene el único propósito de permitirle utilizar nuestros Servicios, de la manera permitida por nuestros Términos. No se le otorgan licencias o derechos por implicación o de otro modo, excepto por las licencias y derechos expresamente concedido a usted. ",
        "pt-terms-of-service-reporting-third-party-copyright-title": "Informar de una infracción de derechos de autor, marca registrada y otra propiedad intelectual de terceros",
        "pt-terms-of-service-reporting-third-party-copyright-subtitle": "Para informar reclamaciones de derechos de autor, marcas comerciales u otra infracción de propiedad intelectual de terceros, envíenos un correo electrónico a",
        "pt-terms-of-service-disclaimers-title": "Exenciones de responsabilidad",
        "pt-terms-of-service-disclaimers-subtitle": "USTED UTILIZA NUESTROS SERVICIOS BAJO SU PROPIO RIESGO Y SUJETO A LAS SIGUIENTES EXENCIONES DE RESPONSABILIDAD. ESTAMOS PROPORCIONANDO NUESTROS SERVICIOS  'TAL CUAL' SIN NINGUNA GARANTÍA EXPRESA O IMPLÍCITA, INCLUYENDO , PERO NO LIMITADO A, GARANTÍAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR, TÍTULO, NO INFRACCIÓN Y LIBRE DEL VIRUS DE LA COMPUTADORA U OTRO CÓDIGO DAÑINO. NO GARANTIZAMOS QUE LA INFORMACIÓN PROPORCIONADA POR NOSOTROS SEA EXACTA, COMPLETA O ÚTIL. QUE NUESTROS SERVICIOS SERÁN OPERACIONALES, LIBRES DE ERRORES, SEGUROS O SEGUROS, O QUE NUESTROS SERVICIOS FUNCIONARÁN SIN INTERRUPCIONES, RETRASOS O IMPERFECCIONES. NO CONTROLAMOS, Y NO SOMOS RESPONSABLES DE, CONTROLAR CÓMO O CUÁNDO NUESTROS USUARIOS UTILIZAN NUESTROS SERVICIOS O LAS CARACTERÍSTICAS, SERVICIOS E INTERFACES QUE PROPORCIONAN NUESTROS SERVICIOS. NO SOMOS RESPONSABLES Y NO ESTAMOS OBLIGADOS A CONTROLAR LAS ACCIONES O LA INFORMACIÓN (INCLUYENDO EL CONTENIDO) DE NUESTROS USUARIOS U OTROS TERCEROS. USTED NOS LIBERA A NOSOTROS, NUESTRAS SUBSIDIARIAS, AFILIADOS Y NUESTROS Y LA DIRECTORES, FUNCIONARIOS, EMPLEADOS, SOCIOS Y AGENTES DE IR (JUNTOS, LAS 'PARTES DE SuperMenu ') DE CUALQUIER RECLAMO, QUEJA, CAUSA DE ACCIÓN, CONTROVERSIA O DISPUTA (JUNTOS, 'RECLAMO') Y DAÑOS, CONOCIDOS Y DESCONOCIDOS , QUE SURJA DE, O ESTÉ DE ALGUNA MANERA RELACIONADA CON DICHA RECLAMACIÓN QUE USTED TIENE CONTRA TERCEROS. USTED RENUNCIA A CUALQUIER DERECHO QUE PUEDA TENER BAJO EL CÓDIGO CIVIL DE CALIFORNIA §1542, O CUALQUIER OTRO ESTATUTO O LEY APLICABLE SIMILAR DE CUALQUIER OTRA JURISDICCIÓN, QUE DICE QUE: UNA LIBERACIÓN GENERAL NO SE EXTIENDE A RECLAMACIONES QUE EL ACREEDOR NO CONOCE O SOSPECHA SU FAVOR AL MOMENTO DE EJECUTAR LA LIBERACIÓN, QUE SI LO CONOCÍA ELLA O ELLA DEBERÁ HABER AFECTADO MATERIALMENTE SU ACUERDO CON EL DEUDOR. ",
        "pt-terms-of-service-limitation-liability-title": "Limitación de responsabilidad",
        "pt-terms-of-service-limitation-liability-subtitle": "LAS PARTES DE SuperMenu  NO SERÁN RESPONSABLES ANTE USTED POR LA PÉRDIDA DE BENEFICIOS O DAÑOS CONSECUENTES, ESPECIALES, PUNITIVOS, INDIRECTOS O INCIDENTALES RELACIONADOS CON, QUE SURJAN O DE CUALQUIER MANERA EN RELACIÓN CON NUESTROS TÉRMINOS, NOSOTROS O NUESTROS SERVICIOS, INCLUSO SI LAS PARTES DE SuperMenu  HAN SIDO ADVERTIDAS DE LA POSIBILIDAD DE DICHOS DAÑOS. NUESTRA RESPONSABILIDAD AGREGADA RELACIONADA CON NUESTROS TÉRMINOS O DE CUALQUIER MODO EN RELACIÓN CON NOSOTROS O NUESTROS SERVICIOS NO EXCEDERÁN EL MAYOR DE CIEN DÓLARES (USD 100) O LA CANTIDAD QUE NOS HA PAGADO EN LOS ÚLTIMOS DOCE MESES. LA RENUNCIA ANTERIOR DE CIERTOS DAÑOS Y LIMITACIÓN DE RESPONSABILIDAD SE APLICARÁ EN LA MEDIDA MÁXIMA PERMITIDA POR LEY. LAS LEYES DE ALGUNOS ESTADOS O JURISDICCIONES PUEDEN NO PERMITIR LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS, POR LO QUE ALGUNAS O TODAS LAS EXCLUSIONES Y LIMITACIONES ESTABLECIDAS ANTERIORMENTE PUEDEN NO APLICARSE EN SU CASO. , LA RESPONSABILIDAD DE LAS PARTES DE SuperMenu  SE LIMITARÁ A LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE. ",
        "pt-terms-of-service-indemnification-title": "Indemnización",
        "pt-terms-of-service-indemnification-subtitle": "Usted acepta defender, indemnizar y eximir de responsabilidad a las Partes de SuperMenu  frente a todas las responsabilidades, daños, pérdidas y gastos de cualquier tipo (incluidos los honorarios y costos legales razonables ) relacionado con, que surja de, o de alguna manera en conexión con cualquiera de los siguientes: (a) su acceso o uso de nuestros Servicios, incluida la información proporcionada en relación con los mismos; (b) su incumplimiento o presunto incumplimiento de nuestros Términos ; o (c) cualquier tergiversación realizada por usted. Cooperará tan plenamente como lo solicitemos en la defensa o resolución de cualquier Reclamación. ",
        "pt-terms-of-service-dispute-resolution-title": "Resolución de disputas",
        "pt-terms-of-service-form-venue": "Foro y lugar",
        "pt-terms-of-service-form-venue-desc": "Si es un usuario de SuperMenu  ubicado en los Estados Unidos o Canadá, la sección 'Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá' a continuación se aplica a usted. Por favor Lea también esa sección con atención y en su totalidad. Si no está sujeto a la sección 'Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá' a continuación, acepta que usted y SuperMenu  resolverán cualquier Reclamación relacionada con, que surja de, o de alguna manera en relación con nuestros Términos, nosotros o nuestros Servicios (cada uno, una 'Disputa' y, en conjunto, 'Disputas') exclusivamente en los tribunales del Reino Unido, y usted acepta someterse a la jurisdicción personal de dichos tribunales con el propósito de litigar todas esas Disputas. Sin perjuicio de lo anterior, usted acepta que, a nuestro exclusivo criterio, podemos optar por resolver cualquier Disputa que tengamos con usted en cualquier tribunal competente del país en el que reside que tenga jurisdicción sobre la Disputa. ",
        "pt-terms-of-service-governing-law": "Ley aplicable.",
        "pt-terms-of-service-governing-law-desc": "Las leyes del Reino Unido rigen nuestros Términos, así como cualquier Disputa, ya sea en un tribunal o arbitraje, que pueda surgir entre SuperMenu  y usted, sin tener en cuenta disposiciones sobre conflicto de leyes. ",
        "pt-terms-of-service-language-clause": "Cláusula de idioma",
        "pt-terms-of-service-language-clause-desc": "El texto completo del presente Contrato, así como los documentos derivados del mismo, incluidos los de los Anexos, se han redactado en varios idiomas, siendo todas las versiones se considera auténtico, pero a efectos legales, el texto en inglés debe tener prioridad de interpretación. ",
        "pt-terms-of-service-availability-termination": "Disponibilidad y terminación de nuestros servicios",
        "pt-terms-of-service-availability-services": "Disponibilidad de nuestros servicios",
        "pt-terms-of-service-availability-services-desc": "Nuestros Servicios pueden ser interrumpidos, incluso por mantenimiento, reparaciones, actualizaciones o fallas en la red o el equipo. Podemos descontinuar algunos o todos nuestros Servicios, incluidas ciertas características y el soporte para ciertos dispositivos y plataformas, en cualquier momento. Eventos fuera de nuestro control pueden afectar nuestros Servicios, como eventos en la naturaleza y otros eventos de fuerza mayor. ",
        "pt-terms-of-service-termination": "Terminación.",
        "pt-terms-of-service-termination-desc": "Podemos modificar, suspender o cancelar su acceso o uso de nuestros Servicios en cualquier momento y por cualquier motivo, como si viola la letra o el espíritu de nuestros Términos o crea daño, riesgo o posible exposición legal para nosotros, nuestros usuarios u otros. Las siguientes disposiciones sobrevivirán a la terminación de su relación con SuperMenu : 'Licencias', 'Renuncias', 'Limitación de responsabilidad', 'Indemnización', 'Disputa Resolución, '' Disponibilidad y terminación de nuestros servicios, '' Otros 'y' Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá '",
        "pt-terms-of-service-other": "Otro",
        "pt-terms-of-service-other-1": "A menos que un acuerdo mutuamente ejecutado entre usted y nosotros establezca lo contrario, nuestros Términos constituyen el acuerdo completo entre usted y nosotros con respecto a SuperMenu  y nuestros Servicios, y reemplazan cualquier acuerdo anterior. ",
        "pt-terms-of-service-other-2": "Es posible que le solicitemos que acepte términos adicionales para algunos de nuestros Servicios en el futuro, que regirán en la medida en que exista un conflicto entre nuestros Términos y dichos términos adicionales. . ",
        "pt-terms-of-service-other-3": "Nuestros Servicios no están destinados a ser distribuidos ni utilizados en ningún país donde dicha distribución o uso infringiría las leyes locales o nos sometería a cualquier normativa de otro país. Nos reservamos el derecho a limitar nuestros Servicios en cualquier país. ",
        "pt-terms-of-service-other-4": "Cumplirá con todas las leyes de control de exportaciones y sanciones comerciales de EE. UU. y fuera de EE. UU. ('Leyes de exportación'). No exportará, re -exportar, proporcionar o transferir nuestros Servicios: (a) a cualquier individuo, entidad o país prohibido por las leyes de exportación; (b) a cualquier persona en listas de partes restringidas del gobierno de EE. UU. o fuera de EE. UU.; o (c) para cualquier propósito prohibidas por las leyes de exportación, incluidas las armas nucleares, químicas o biológicas, o las aplicaciones de tecnología de misiles sin las autorizaciones gubernamentales requeridas. No utilizará ni descargará nuestros Servicios si se encuentra en un país restringido, si actualmente figura en cualquier EE. UU. o lista de partes restringidas no estadounidenses, o para cualquier propósito prohibido por las leyes de exportación, y no disfrazará su ubicación a través de proxy de IP u otros métodos. ",
        "pt-terms-of-service-other-5": "Nuestras Condiciones están escritas en inglés (EE. UU.). Cualquier versión traducida se proporciona únicamente para su conveniencia. En la medida en que cualquier versión traducida de nuestras Condiciones entre en conflicto con la versión en inglés, los controles de la versión en inglés. ",
        "pt-terms-of-service-other-6": "Cualquier enmienda o renuncia a nuestras Condiciones requiere nuestro consentimiento expreso",
        "pt-terms-of-service-other-7": "Podemos enmendar o actualizar estos Términos. Le notificaremos las enmiendas a nuestros Términos, según corresponda, y actualizaremos la fecha de 'Última modificación' en la parte superior de nuestra Términos. El uso continuado de nuestros Servicios confirma su aceptación de nuestros Términos, en su forma enmendada. Si no está de acuerdo con nuestros Términos, en su forma enmendada, debe dejar de usar nuestros Servicios. Por favor, revise nuestros Términos de vez en cuando. ",
        "pt-terms-of-service-other-8": "Todos nuestros derechos y obligaciones bajo nuestros Términos son libremente asignables por nosotros a cualquiera de nuestras afiliadas o en relación con una fusión, adquisición, reestructuración o venta de activos, o por operación de la ley o de otra manera, y podemos transferir su información a cualquiera de nuestras afiliadas, entidades sucesoras o nuevo propietario. ",
        "pt-terms-of-service-other-9": "No transferirá ninguno de sus derechos u obligaciones bajo nuestros Términos a nadie más sin nuestro consentimiento previo por escrito.",
        "pt-terms-of-service-other-10": "Nada en nuestros Términos impedirá que cumplamos con la ley",
        "pt-terms-of-service-other-11": "Salvo lo contemplado en el presente, nuestros Términos no otorgan derechos a ningún tercero beneficiario",
        "pt-terms-of-service-other-12": "Si no cumplimos con alguno de nuestros Términos, no se considerará una renuncia",
        "pt-terms-of-service-other-13": "Si alguna disposición de estos Términos se considera ilegal, nula o inaplicable por cualquier motivo, dicha disposición se considerará separable de nuestros Términos y no afectará la validez y aplicabilidad de las disposiciones restantes, excepto lo establecido en la sección 'Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá' - 'Divisibilidad' a continuación. ",
        "pt-terms-of-service-other-14": "Nos reservamos todos los derechos que no le otorguemos expresamente. En ciertas jurisdicciones, usted puede tener derechos legales como consumidor, y nuestras Condiciones no tienen la intención de limitar dicho consumidor. derechos legales que no pueden ser renunciados por contrato. ",
        "pt-terms-of-service-other-15": "Siempre agradecemos sus comentarios u otras sugerencias sobre SuperMenu  y nuestros Servicios, pero comprende que podemos utilizar sus comentarios o sugerencias sin ninguna obligación de compensarlo por ellos (solo ya que no tienes obligación de ofrecerlos). ",
        "pt-terms-of-service-attribution-notice-title": "Aviso de atribución",
        "pt-terms-of-service-attribution-notice-subtitle": "Agradecemos a las siguientes organizaciones e individuos por sus contribuciones:",
    
    /* --------------- COMPONENTS --------------- */

        /* --- Ads --- */
        loadMenu: "Cargando tu menú...",
        adProduct1: "Apple Cider Vinegar Gummy Vitamins",
        adProduct2: "REVLON One-Step Hair Dryer And Volumizer",
        adProduct3: "Furbo Dog Camera: Treat Tossing",
        adProduct4: "Lodge Miniature Skillet",
        adProduct5: "Wool Dryer Balls",
        adProduct6: "Jabra Elite Active 75t True Wireless Bluetooth Earbuds",
        adProduct7: "High Waist Biker Shorts Yoga Workout Running",
        adProduct8: "LEVOIT Air Purifier",

        /* --- Footer --- */
        freeQRMenu: "Menú QR Gratuito",
        createAccountNow: "Crea una cuenta ahora",
        termsPrivacy: "Términos & Privacidad",

        /* --- Gift Button --- */
            // Modal
            giftModalSubtitle1: "Te suscribiste hace <bold>unos minutos</bold>",
            giftModalSubtitle2: "Te suscribiste hace <bold>{{timeCreated}} horas</bold>",

        /* --- Help Button --- */
        helpButtonTitle: "Soporte",
            // Modal 
            helpButtonModalTitle: "Contacta Nuestro Equipo de Soporte",

        /* --- Mobile Empty --- */
        mobileEmptyTitle: "Tu cuenta esta lista!",
        mobileEmptyDescription: "Inicia sesión en tu computador para empezar a usar SuperMenu",

        /* --- My Card Panel --- */
        getStarted: "Empezar ahora",
        selectPlan: "Escoger plan",
        currentPlan: "Tu plan actual",
        feedbackDescription: "Plan <bold><a>Gratis sin Anuncios</a></bold> registrandote a nuestro<bold><a> Programa de Encuesta! </a></bold>",
        limitedSeats: "Cupos Limitados",

        /* --- Plans --- */
        save20: "Ahorra 20%",
        billedMonthly: "MENSUAL",
        Monthly: "MENSUAL",
        billedYearly: "ANUAL",
        Yearly: "ANUAL",

        freePlanTitle: "Gratis",
        freePlanDescription: "3 códigos QR dinámicos. Scans ilimitados. Reportes. Anuncios de 3 segundos",

        feedbackPlanTitle: "Encuesta",
        feedbackPlanDescription: "3 códigos QR dinámicos. Scans ilimitados. Reportes. Sin anuncios",

        /* --- Side Content --- */
        upgradePlan: "Actualiza tu Plan",

        /* -- Account -- */
        accountTitle: "Detalles de Cuenta",

        tab1: "Cuenta",
        tab2: "Facturas",
        tab3: "Tu Producto",

            // Modal password
            modalPassTitle: "Ingresa Tu Contraseña",
            placeholderPass: "Tu Contraseña",

            // Tab 1 - Account
            yourAccount: "Tu Cuenta",
            personalInfo: "Información Personal",

            yourCompany: "Tu Empresa",

            firstName: "Nombres",
            lastName: "Apellidos",
            emailAddress: "Email",
            phoneNumber: "Teléfono",

            currentPassword: "Contraseña Actual",
            newPassword: "Nueva Contraseña",
            confirmNewPassword: "Confirmar Nueva Contraseña",

            timeZone: "Zona Horaria",

            companyName: "Nombre de Empresa",

            streetAddress: "Dirección",
            country: "País",
            city: "Ciudad",
            stateProvince: "Estado/Provincia",
            postalCode: "Código Postal",

            // Tab 2 - Invoices
            tab2Title: "Facturas",
            tab2EmptySectionTitle: "Tus facturas van aquí",

            // Tab 3 - Your Product
            yourPlan: "Tu Plan",
            cancelPlan: "Cancelar Plan",
            emailsSent: "emails enviados este mes",
            inUse: "{{count}} Menú en uso",
            inUse_plural: "{{count}} menús en uso",

        /* --- Side Nav --- */
        modalEditMenuTitle: "Editar Menú",
        modalEditMenuContent1: "Editar Nombre",
        modalEditMenuContent2: "Eliminar Menú",
            /* -- Sub Menu -- */

                /* -- Menu -- */
                    // Modal delete menu
                    modalDeleteMenuTitle: "Importante",
                    modalDeleteMenuContent: "Estás seguro que quieres eliminar",
                    // Modal update name menu
                    modalUpdateNameMenuTitle: "Nombre del Menú",

            /* -- Sub Menu Click -- */
                    
                /* -- Menus -- */     
                newMenuWord: "Nevo Menú",
                addNewMenuWord: "Añadir Nevo Menú",
                addMenu: "Añadir menú",
                
                /* -- Profile -- */
                getHelp: "Ayuda",
                logOut: "Cerrar Sesión",
                profileTitle: "Actualiza tu Plan",
                profileContent: "Elimina los anuncios cuando tus clientes escanean tu código. <br> <br> Elimina los anuncios de tu código cuando pasas a un plan pago.",
                profileButton: "Actualizar Ahora",

        /* --- Temp Toast --- */
        tempToast1Content: "Haz recolectado el número máximo de clientes permitidos por tu plan. Para seguir recolectando clientes, por favor",
        tempToast1Link: "actualiza tu plan",

        tempToast2Content: "Tu periodo de prueba gratis expirará {{leftTrialDays}} días. Para continuar disfrutando SuperMenu",
        tempToast2Link: "por favor haz click aquí",

        tempToast3Content: "Tu periodo de prueba ha expirado. Para continuar disfrutando SuperMenu",
        tempToast3Link: "por favor haz click aquí",

        tempToast4Content: "Parece que tu conexión a Internet tiene problemas. Es posible que algunas acciones no funcionen hasta que vuelva a estar en línea",
        
        tempToast5Content: "Parece que algo ha salido mal, si tienes problemas",
        tempToast5Link: "por favor haz click aquí",
        
    /* --------------- TOAST --------------- */

        /* --- Error --- */
        toastUnexpectedError: "Error inesperado, por favor inténtalo de nuevo",
        toastErrorFirstName: "Por favor ingresa tus nombre",
        toastErrorLastName: "Por favor ingresa tus apellidos",
        toastErrorEmail: "Por favor ingresa tu email",
        toastErrorCompany: "Por favor ingresa el nombre de tu empresa",
        toastErrorPass: "Por favor ingresa tu contraseña",
        toastErrorNewPass: "Por favor ingresa tu nueva contraseña",
        toastErrorConfirmNewPass: "Por favor confirma tu nueva contraseña",
        toastErrorAddress: "Ingresa tu dirección",
        toastErrorCountry: "Por favor ingresa tu país",
        toastErrorCity: "Por favor ingresa tu ciudad",
        toastErrorState: "Por favor ingresa tu estado/provincia",
        toastErrorPostalCode: "Por favor ingresa tu código postal",
        toastErrorUrl: "Por favor ingresa una URL válida",
        toastErrorMenuName: "Por favor ingresa el nombre de tu menú",
        toastErrorLoadImage: "Error cargando la imagen, por favor inténtalo de nuevo",
        toastErrorEmailNotSent: "Error enviando tu código, por favor inténtalo de nuevo",
        toastErrorSizeExceed: "El archivo no debe sobrepasar 100 MB",
        toastErrorUploadPdrOrImage: "Por favor sube una imagen o un PDF",
        toastErrorInvalidImageFormat: "Por favor sube una imagen .jpeg o .png",
        toastErrorCustomUrl: "Por favor ingresa tu URL personalizada",
        toastErrorInvalidEmail: "Por favor ingresa un email válido",
        toastErrorInvalidPass: "Tu contraseña debe contener 6 o más caracteres",
        toastErrorPassNotMatch: "Tu nueva contraseña no concuerda con la contraseña de confirmación",
        toastErrorCustomUrlNotAvailable: "Esta URL personalizada no está disponible, por favor intenta una diferente",
        toastErrorEmailNotFound: "La dirección de email no fue encontrada, por favor inténtalo de nuevo",

        /* --- Info --- */

        /* --- Success --- */
        toastSuccessQRSent: "Tu código QR fue enviado a tu correo. Por favor revisa el folder de no deseados si no llega al principal.",
        toastSuccessEmailSent: "Tu email fue enviado",
        toastSuccessLinkCopied: "Link copiado",
        toastSuccessPassUpdated: "Tu nueva contraseña fue actualizada",
        toastSuccessLinkPassSent: "Un link para restablecer tu contraseña fue enviado a tu email",
        toastSuccessEmailUpdated: "Tu email fue actualizado",
        toastSuccessUnsubscribed: "Se ha dado de baja",
        toastSuccessInfoUpdated: "Tu información fue actualizada",
        toastSuccessMenuDeleted: "Tu menú fue eliminado",

    /* --------------- ERRORS AUTH FIREBASE --------------- */
        
    "admin-restricted-operation": "This operation is restricted to administrators only.",
    "argument-error": "",
    "app-not-authorized": "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    "app-not-installed": "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.",
    "captcha-check-failed": "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
    "code-expired": "The SMS code has expired. Please re-send the verification code to try again.",
    "cordova-not-ready": "Cordova framework is not ready.",
    "cors-unsupported": "This browser is not supported.",
    "credential-already-in-use": "This credential is already associated with a different user account.",
    "custom-token-mismatch": "The custom token corresponds to a different audience.",
    "requires-recent-login": "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
    "dynamic-link-not-activated": "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.",
    "email-change-needs-verification": "Multi-factor users must always have a verified email.",
    "email-already-in-use": "The email address is already in use by another account.",
    "expired-action-code": "The action code has expired. ",
    "cancelled-popup-request": "This operation has been cancelled due to another conflicting popup being opened.",
    "internal-error": "An internal error has occurred.",
    "invalid-app-credential": "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
    "invalid-app-id": "The mobile app identifier is not register for the current project.",
    "invalid-user-token": "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    "invalid-auth-event": "An internal error has occurred.",
    "invalid-verification-code": "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.",
    "invalid-continue-uri": "The continue URL provided in the request is invalid.",
    "invalid-cordova-configuration": "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
    "invalid-custom-token": "The custom token format is incorrect. Please check the documentation.",
    "invalid-dynamic-link-domain": "The provided dynamic link domain is not configured or authorized for the current project.",
    "invalid-email": "The email address is badly formatted.",
    "invalid-api-key": "Your API key is invalid, please check you have copied it correctly.",
    "invalid-cert-hash": "The SHA-1 certificate hash provided is invalid.",
    "invalid-credential": "The supplied auth credential is malformed or has expired.",
    "invalid-message-payload": "The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-multi-factor-session": "The request does not contain a valid proof of first factor successful sign-in.",
    "invalid-oauth-provider": "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.",
    "invalid-oauth-client-id": "The OAuth client ID provided is either invalid or does not match the specified API key.",
    "unauthorized-domain": "This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.",
    "invalid-action-code": "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
    "wrong-password": "The password is invalid or the user does not have a password.",
    "invalid-persistence-type": "The specified persistence type is invalid. It can only be local, session or none.",
    "invalid-phone-number": "The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].",
    "invalid-provider-id": "The specified provider ID is invalid.",
    "invalid-recipient-email": "The email corresponding to this action failed to send as the provided recipient email address is invalid.",
    "invalid-sender": "The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-verification-id": "The verification ID used to create the phone auth credential is invalid.",
    "invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
    "multi-factor-info-not-found": "The user does not have a second factor matching the identifier provided.",
    "multi-factor-auth-required": "Proof of ownership of a second factor is required to complete sign-in.",
    "missing-android-pkg-name": "An Android Package Name must be provided if the Android App is required to be installed.",
    "auth-domain-config-required": "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.",
    "missing-app-credential": "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.",
    "missing-verification-code": "The phone auth credential was created with an empty SMS verification code.",
    "missing-continue-uri": "A continue URL must be provided in the request.",
    "missing-iframe-start": "An internal error has occurred.",
    "missing-ios-bundle-id": "An iOS Bundle ID must be provided if an App Store ID is provided.",
    "missing-multi-factor-info": "No second factor identifier is provided.",
    "missing-multi-factor-session": "The request is missing proof of first factor successful sign-in.",
    "missing-or-invalid-nonce": "The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.",
    "missing-phone-number": "To send verification codes, provide a phone number for the recipient.",
    "missing-verification-id": "The phone auth credential was created with an empty verification ID.",
    "app-deleted": "This instance of FirebaseApp has been deleted.",
    "account-exists-with-different-credential": "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
    "network-request-failed": "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
    "no-auth-event": "An internal error has occurred.",
    "no-such-provider": "User was not linked to an account with the given provider.",
    "null-user": "A null user object was provided as the argument for an operation which requires a non-null user object.",
    "operation-not-allowed": "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
    "operation-not-supported-in-this-environment": 'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    "popup-blocked": "Unable to establish a connection with the popup. It may have been blocked by the browser.",
    "popup-closed-by-user": "The popup has been closed by the user before finalizing the operation.",
    "provider-already-linked": "User can only be linked to one identity for the given provider.",
    "quota-exceeded": "The project's quota for this operation has been exceeded.",
    "redirect-cancelled-by-user": "The redirect operation has been cancelled by the user before finalizing.",
    "redirect-operation-pending": "A redirect sign-in operation is already pending.",
    "rejected-credential": "The request contains malformed or mismatching credentials.",
    "second-factor-already-in-use": "The second factor is already enrolled on this account.",
    "maximum-second-factor-count-exceeded": "The maximum allowed number of second factors on a user has been exceeded.",
    "tenant-id-mismatch": "The provided tenant ID does not match the Auth instance's tenant ID",
    "timeout": "The operation has timed out.",
    "user-token-expired": "The user's credential is no longer valid. The user must sign in again.",
    "too-many-requests": "We have blocked all requests from this device due to unusual activity. Try again later.",
    "unauthorized-continue-uri": "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.",
    "unsupported-first-factor": "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.",
    "unsupported-persistence-type": "The current environment does not support the specified persistence type.",
    "unsupported-tenant-operation": "This operation is not supported in a multi-tenant context.",
    "unverified-email": "The operation requires a verified email.",
    "user-cancelled": "The user did not grant your application the permissions it requested.",
    "user-not-found": "There is no existing user record corresponding to the provided identifier.",
    "user-disabled": "The user account has been disabled by an administrator.",
    "user-mismatch": "The supplied credentials do not correspond to the previously signed in user.",
    "user-signed-out": "",
    "weak-password": "The password must be 6 characters long or more.",
    "web-storage-unsupported": "This browser is not supported or 3rd party cookies and data may be disabled.",

    /* --------------- CUSTOM ERRORS AUTH FIREBASE --------------- */
    
    "o-unavailable": "Parece que la conexión es inestable, lo estamos intentando de nuevo",
    "o-user-not-found": "No hemos encontrado a un usuario con este email",
    "o-popup-closed-by-user": "Haz cerrado la ventana emergente",
    "o-email-already-in-use": "El email ya está en uso por otra cuenta",
};