import { ConvertBlobToArrayBuffer } from "./ConvertBlobToArrayBuffer";
import { ConvertBase64ToFile } from "./ConvertBase64ToFile";
import { ConvertFileToBase64 } from "./ConvertFileToBase64";
import { functions, analytics } from "../controller/firebase";
import axios from "axios";

export function CreateQrCode({
    url = window.origin,
    logo = null,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
}) {
    return new Promise((resolve, reject) => {
        const reqBody = {
            data: url,
            config: {
                body: "star",
                eye: "frame1",
                eyeBall: "ball1",
                erf1: ["fh"],
                brf1: ["fh"],
                erf3: ["fv", "fh"],
                brf3: ["fv", "fh"],
                bodyColor: "#000",
                eye1Color: "#000",
                eye2Color: "#000",
                eye3Color: "#000",
                eyeBall1Color: "#000",
                eyeBall2Color: "#000",
                eyeBall3Color: "#000",
            },
            size: 600,
            download: false,
            file: "png",
        };

        // If there is a logo to add then add it
        if (logo) {
            reqBody.config.logo = logo;
        } else {
            analytics.logEvent("qr_code_create", {
                logo: "no_logo",
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"no_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
            });
        }

        //Call function
        const createQrCode = functions.httpsCallable("createQrCode");

        const dataApi = {
            options: reqBody,
        };

        createQrCode(dataApi)
            .then(async function(result) {
                const obj = result.data.res;

                if (obj !== null) {
                    // get File from obj
                    var blob = ConvertBlobToArrayBuffer(obj);

                    var str = btoa(
                        new Uint8Array(blob).reduce(function(data, byte) {
                            return data + String.fromCharCode(byte);
                        }, "")
                    );

                    var newValue = `data:image/png;base64,${str}`;

                    const file = ConvertBase64ToFile({
                        dataUrl: newValue,
                        fileName: "qr.png",
                    });
                    resolve(file);
                }
                resolve(null);
            })
            .catch(function(error) {
                analytics.logEvent("new_error", {
                    description: `L86 @ CreateQrCode.js | ${error.code} - ${error.message}`,
                });
                reject(error);
            });
    });
}

export function CreateQrCodeWithLogo({
    logo = null,
    url = window.origin,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
}) {
    return new Promise(async(resolve, reject) => {
        if (logo) {
            const base64 = await ConvertFileToBase64(logo);

            // Call function
            const createQrLogo = functions.httpsCallable("createLogoQrCode");

            var dataApi = {
                image: base64,
            };

            createQrLogo(dataApi)
                .then(async function(result) {
                    // Create QR Code
                    const qrCode = await CreateQrCode({
                        url: url,
                        logo: result.data.response.file,
                    });
                    analytics.logEvent("qr_code_create", {
                        logo: "custom_logo",
                        prices_type: pricesType,
                        new_user_id: userId,
                        venue_id: venueId,
                        qr_flow_id: qrFlowId,
                        description: `${"custom_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
                    });
                    resolve(qrCode);
                })
                .catch(function(error) {
                    analytics.logEvent("new_error", {
                        description: `L131 @ CreateQrCode.js | ${error.code} - ${error.message}`,
                    });
                    reject(error);
                });
        } else {
            const qrCode = await CreateQrCode({
                url: url,
                logo: "https://supermenuapp.com/static/media/super_menu_qr_logo.5b86eecc.png",
            });
            analytics.logEvent("qr_code_create", {
                logo: `default_logo`,
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"default_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId} `,
            });
            resolve(qrCode);
        }
    });
}

export function CreateQRCodeFast({
    url = window.origin,
    logo = null,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    config = {},
}) {
    return new Promise((resolve, reject) => {
        let configQR = {
            body: "dot",
            eye: "frame1",
            eyeBall: "ball1",
            erf1: ["fh"],
            brf1: ["fh"],
            erf3: ["fv", "fh"],
            brf3: ["fv", "fh"],
            bodyColor: "#000",
            bgColor: "#fff",
            eye1Color: "#000",
            eye2Color: "#000",
            eye3Color: "#000",
            eyeBall1Color: "#000",
            eyeBall2Color: "#000",
            eyeBall3Color: "#000",
        };

        if (config.body) {
            configQR = {...config };
        }

        const options = {
            method: "POST",
            url: "https://qrcode-monkey.p.rapidapi.com/qr/custom",
            responseType: "arraybuffer",
            data: {
                data: url,
                config: {
                    ...configQR,
                },
                size: 600,
                download: false,
                file: "png",
            },
            headers: {
                "content-type": "application/json",
                "x-rapidapi-key": process.env.REACT_APP_QR_MONKEY,
                "x-rapidapi-host": "qrcode-monkey.p.rapidapi.com",
            },
        };

        // If there is a logo to add then add it
        if (logo) {
            options.data.config.logo = logo;
            options.data.config.logoMode = "clean";
        } else {
            analytics.logEvent("qr_code_create", {
                logo: "no_logo",
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"no_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
            });
        }

        axios
            .request(options)
            .then(function(response) {
                var blob = response.data;
                var str = btoa(
                    new Uint8Array(blob).reduce(function(data, byte) {
                        return data + String.fromCharCode(byte);
                    }, "")
                );

                var newValue = `data:image/png;base64,${str}`;

                const file = ConvertBase64ToFile({
                    dataUrl: newValue,
                    fileName: "qr.png",
                });
                resolve({ file: file, image: newValue });
            })
            .catch(function(error) {
                analytics.logEvent("new_error", {
                    description: `L240 @ CreateQrCode.js | ${error.code} - ${error.message}`,
                });
                reject(error);
            });
    });
}

export function CreateQrCodeWithLogoFast({
    logo = null,
    url = window.origin,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    config = {},
}) {
    return new Promise(async(resolve, reject) => {
        if (logo) {
            const data = new FormData();
            data.append("file", logo);

            const optionsLogo = {
                method: "POST",
                url: "https://qrcode-monkey.p.rapidapi.com/qr/uploadImage",
                data: data,
                headers: {
                    "content-Type": "multipart/form-data",
                    "content-type": "application/json",
                    "x-rapidapi-key": process.env.REACT_APP_QR_MONKEY,
                    "x-rapidapi-host": "qrcode-monkey.p.rapidapi.com",
                },
            };

            axios
                .request(optionsLogo)
                .then(async function(response) {
                    // Create QR Code
                    const qrCode = await CreateQRCodeFast({
                        url: url,
                        logo: response.data.file,
                        config: config,
                    });
                    analytics.logEvent("qr_code_create", {
                        logo: "custom_logo",
                        prices_type: pricesType,
                        new_user_id: userId,
                        venue_id: venueId,
                        qr_flow_id: qrFlowId,
                        description: `${"custom_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
                    });
                    resolve(qrCode);
                })
                .catch(function(error) {
                    analytics.logEvent("new_error", {
                        description: `L294 @ CreateQrCode.js | ${error.code} - ${error.message}`,
                    });
                    reject(error);
                });
        } else {
            const qrCode = await CreateQRCodeFast({
                url: url,
                logo: "https://supermenu-41660--test-2rt0k1k4.web.app/static/media/default_logo.a10a37cc.png",
            });
            analytics.logEvent("qr_code_create", {
                logo: `default_logo`,
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"default_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId} `,
            });
            resolve(qrCode);
        }
    });
}