import { analytics, functions } from "../controller/firebase";

export async function GetScans({ venue_id = "" }) {
  return new Promise(async (resolve, reject) => {
    // Call function
    const bigQuery = functions.httpsCallable("sendBigQueryData");
    const data = {
      venueId: venue_id,
    };
    bigQuery(data)
      .then(async function (result) {
        resolve(result.data.response);
      })
      .catch(function (error) {
        // Save error
        analytics.logEvent("new_error", {
          description: `L17 @ GetScans.js | ${error.code} - ${error.message} -${venue_id}`,
        });
        reject("Error");
      });
  });
}
