import React from "react";
import "./IphoneModal.scss";
import { Modal, Button } from "react-materialize";
import { Fragment } from "react";

import { useTranslation } from "react-i18next";

const IphoneModal = ({
  title,
  image = null,
  subtitle = "",
  content,
  actions = null,
  modalId,
  button,
  fullWidth = false,
  cancelText,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {button}
      <Modal
        className={fullWidth ? "o-iphone-modal o-full-width" : "o-iphone-modal"}
        actions={[
          <Button flat modal="close">
            {cancelText ? cancelText : t("cancelWord")}
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={
          <Fragment>
            <div className="o-title">{title}</div>
            <div className="o-image">{image}</div>
            <div className="o-subtitle">{subtitle}</div>
          </Fragment>
        }
        id={modalId}
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
      >
        <div className="hr"></div>
        {content}
        {actions !== null ? actions : null}
        <div className="hr"></div>
      </Modal>
    </div>
  );
};

export default IphoneModal;
