/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import { db, analytics } from "../controller/firebase";

import { openToast, ERROR } from "../constant/Toast";

import i18n from "i18next";

/* Constants or States */
const initData = {
  loading: true,
  data: [],
};

const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";

const GET_CUSTOMERS_EXIT = "GET_CUSTOMERS_EXIT";
const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

const RESTART_CUSTOMERS = "RESTART_CUSTOMERS";

/* Reducer (Save call API in constant) */
export default function customersReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return { ...state, loading: true };
    case GET_CUSTOMERS_EXIT:
      return { ...state, data: action.payload, loading: false };
    case GET_CUSTOMERS_ERROR:
      return { ...state, loading: false };
    case RESTART_CUSTOMERS:
      return { ...initData };
    default:
      return state;
  }
}

/* Actions (Calls API) */
export const getCustomersData =
  ({ venueId = "", maxCustomers = 0, readDataBase = true }) =>
  async (dispatch) => {
    // Init loading
    dispatch({
      type: LOADING_CUSTOMERS,
    });

    // Validate if the data exist in the browser
    if (!readDataBase) {
      if (localStorage.getItem("customers")) {
        dispatch({
          type: GET_CUSTOMERS_EXIT,
          payload: [...JSON.parse(localStorage.getItem("customers")).data],
        });
        return;
      }
    }

    if (localStorage.getItem("auth")) {
      // Get user's uid from browser
      const uid = JSON.parse(localStorage.getItem("auth")).uid;
      let data = [];

      try {
        if (maxCustomers > 0) {
          const res = await db
            .collection("users")
            .doc(uid)
            .collection("venues")
            .doc(venueId)
            .collection("customers")
            .orderBy("created")
            .get();

          res.forEach(async (customers) => {
            data = [...data, { ...customers.data() }];
          });

          data = data.filter((item, index) => index < maxCustomers);

          dispatch({
            type: GET_CUSTOMERS_EXIT,
            payload: data,
          });

          // Save in the browser
          localStorage.setItem(
            "customers",
            JSON.stringify({
              data,
            })
          );
        } else {
          dispatch({
            type: GET_CUSTOMERS_ERROR,
          });
        }
      } catch (error) {
        // Show toast unexpected error reload
        document
          .getElementById("toast-unexpected-error")
          .classList.remove("hide");
        // Save analytics
        analytics.logEvent("new_error", {
          description: `L89 @ customerDucks.js | ${error.code} - ${error.message}`,
        });

        dispatch({
          type: GET_CUSTOMERS_ERROR,
        });
      }
    }
  };

// Reset customers
export const restartDataCustomers = () => async (dispatch) => {
  dispatch({
    type: RESTART_CUSTOMERS,
  });
};
