import React from "react";
import "./Loading.scss";

import { useSelector } from "react-redux";

import { Row, Preloader, Col } from "react-materialize";

const Loading = ({ mobile = true, fullHeight = true }) => {
  // Redux states
  const showPercentage = useSelector((store) => store.qrFlow.newImagesAdding);
  const totalPercentage = useSelector(
    (store) => store.qrFlow.totalNewImagesUploaded
  );
  const valuePercentage = useSelector(
    (store) => store.qrFlow.numNewImagesUploaded
  );

  return (
    <div
      className={
        mobile
          ? fullHeight
            ? "o-loading-container o-mobile o-full-height"
            : "o-loading-container o-mobile"
          : "o-loading-container"
      }
    >
      <Row>
        <Col s={12} className="center-align">
          <Preloader active color="blue" flashing={false} size="big" />
        </Col>
        {showPercentage && (
          <Col s={12} className="center-align">
            <p>{`${
              (valuePercentage * 100) / totalPercentage
                ? Math.round((valuePercentage * 100) / totalPercentage)
                : 0
            }%`}</p>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Loading;
